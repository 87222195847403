import Vue from 'vue'
import App from './App.vue'
import router from './router.js';
import store from './store.js';
import VueRouter from 'vue-router'
import  axios  from  'axios'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueI18n from 'vue-i18n'; 
   
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCheck, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faCheck, faCircleInfo, faTriangleExclamation)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
    
Vue.config.productionTip = true   
            
// Lokalisierungsdateien importieren
import en from './i18n/en.json';
import de from './i18n/de.json';

Vue.prototype.$http = axios;

Vue.prototype.$konva = window.Konva 

Vue.use(VueI18n);  

const messages = {
  en: en, 
  de: de
};

const i18n = new VueI18n({
  locale: 'en', // Standard-Sprache setzen
  fallbackLocale: 'de',
  messages
});

Vue.use(VueRouter);
Vue.use(VueLoading)
Vue.use(VueSweetalert2);

Vue.use(require('vue-moment'));

// https://inspect.8p2.de/api/users/inbe/authenticate

Vue.prototype.$apiBase = (Vue.config.productionTip) ? "/api"  : "http://localhost:3202" 
Vue.prototype.$http.defaults.baseURL = Vue.prototype.$apiBase;

Vue.prototype.$pdfBase = (Vue.config.productionTip) ? "https://panel.inspect-app.de/api"  : "http://localhost:3202" 

new Vue({
  store,
  router,
  render: h => h(App),
  i18n
}).$mount('#app')
