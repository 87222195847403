<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { accountSetup } from "@/services/login.service";
import axios from "axios";
import SocketioService from '@/services/socketio.service.js';
import Vue from 'vue'



export default {
  name: "App",
  components: {},
  created() {
    if (!this.$route.meta.nocheck) {

      if (localStorage.getItem('token')) {
        this.$store.state.token = localStorage.getItem('token')
      }
      
      axios.defaults.headers.common["x-access-token"] = this.$store.state.token;
      axios.defaults.headers.common["x-access-token"] = this.$store.state.token;

      this.$store.state.account_setup = true;
      this.$store.commit("set_loading_page", "setup");


      
      this.$store.socket = SocketioService
      this.$store.socket.setupSocketConnection(Vue.prototype.$apiBase.replace("/api", ""))
//this.$store.socket.sendRSelectRemark
      accountSetup()
        .then((d) => {

          //console.log("d", d)
          if (d.data.name) {
            this.$store.state.name = d.data.name;
          }
          if (d.data.login_system) {
            this.$store.state.login_system = d.data.login_system;
          }
          if (d.data.userId) {
            this.$store.state.userId = d.data.userId;
          }

          if (d.data.company) {
            this.$store.state.company = d.data.company;
          }

          let newTestProject = JSON.parse(JSON.stringify(this.$store.state.testProject))

          if (d.data.userId && !d.data.old_testproject) {
            newTestProject.projectId = "00000000" + d.data.userId
            newTestProject._id = "00000000" + d.data.userId
            newTestProject.uid = "00000000" + d.data.userId
            newTestProject.projectnumber = "00000000" + d.data.userId
          }

          if (d.data.projects) {
            d.data.projects.push(newTestProject)
            this.$store.commit("set_projects", d.data.projects);
          } else {
            this.$store.commit("set_projects", [newTestProject]);
          }

          if (d.data.account_role) {
            this.$store.state.account_role = d.data.account_role
          }

          this.$store.commit("set_loading_page", null);
        })
        .catch((e) => {
          this.$store.dispatch("api_error", e);
        });
    }
  },
};
</script>

<style>
@media only screen and (max-width: 760px) {
  .hide-mobile {
    display: none;
  }
}
</style>
