import * as axios from "axios";

function getProject(uid) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/project?uid=` + uid;
  return (
    axios
      .get(url, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        if (x.status == "error") {
          return Promise.reject(x);
        }
        return x;
      })
  );
}

function createProject(projectNumber, name) {
  const data = {
    projectNumber: projectNumber,
    projectName: name,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/create-project`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function updateProject(project) {
  const data = {
    project: project,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/update-project`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function getCollaborators(uid) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/collaborators-project?uid=` + uid;
  return (
    axios
      .get(url, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function getMembers(uid) {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/members-project?uid=` + uid;
  return (
    axios
      .get(url, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function addCollaborator(username, project_provider, project_uid ) {
    const data = {
      project_username: username,
      project_uid: project_uid,
      project_provider: project_provider
    };
  
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };
  
    const url = `/inspect-backend/add-collaborator-project`;
    return (
      axios
        .post(url, data, headers)
        // get data
        .then((x) => x.data)
        // add url field
        .then((x) => {
          return x;
        })
    );
  }


function addMember(username, project_uid) {
  const data = {
    project_username: username,
    project_uid: project_uid,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/add-member-project`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function updateEditor(user, project_uid) {
  const data = {
    project_user_uid: user.uid,
    project_user_editor: user.editor,
    project_uid: project_uid,
  };

  console.log("data", data);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/editor-member-project`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}


function removeCollaborator(user, project_uid) {
    const data = {
      project_user_uid: user.uid,
      project_uid: project_uid,
    };
  
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    };
  
    const url = `/inspect-backend/remove-collaborator-project`;
    return (
      axios
        .post(url, data, headers)
        // get data
        .then((x) => x.data)
        // add url field
        .then((x) => {
          return x;
        })
    );
  }

function removeMember(user, project_uid) {
  const data = {
    project_user_uid: user.uid,
    project_uid: project_uid,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/remove-member-project`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function deleteProject(project) {
  const data = {
    project_uid: project.uid,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/delete-project`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function listInspections(project_uid) {
  const data = {
    project_uid: project_uid,
  };

  console.log("---- data", data, "project_uid", project_uid);

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/list-inspections`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

function getInspection(project_uid, inspection_id) {
  const data = {
    project_uid: project_uid,
    inspection_id: inspection_id,
  };

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  };

  const url = `/inspect-backend/get-inspection`;
  return (
    axios
      .post(url, data, headers)
      // get data
      .then((x) => x.data)
      // add url field
      .then((x) => {
        return x;
      })
  );
}

export {
  getProject,
  createProject,
  updateProject,
  deleteProject,
  getMembers,
  getCollaborators,
  addMember,
  updateEditor,
  addCollaborator,
  removeCollaborator,
  removeMember,
  listInspections,
  getInspection,
};
