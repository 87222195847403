<template>
  <div>
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap">
          <Header v-show="!loading_page" />
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container wide-xl">
              <div class="nk-content-inner">
                <Navigation v-show="!loading_page" />
                <!--
                <div class="nk-content-body" v-show="loading_page">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                -->

                <div class="nk-content-body" v-show="!loading_page">
                  <div class="nk-content-wrap">

                    <!-- .nk-block-head -->

                    <div class="nk-block nk-block-lg">
                      <div class="nk-block-head">
                        <div class="nk-block-head-content">
                          <h4 class="nk-block-title">Contacts</h4>
                        </div>
                      </div>

                      <div class="btn btn-secondary btn-dim btn-sm" @click="addContact()" style="margin: 10px 0 20px 0">
                        <span>Add contact</span>
                      </div>

                      <div class="form-control-wrap" style="margin-bottom: 20px">
                        <div class="input-group">
                          <input v-model="searchkey" type="text" class="form-control" placeholder="Filter" />
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col" style="cursor: pointer" @click="sortCol('company')">
                                Company
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('name')">
                                Name
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('city')" class="hide-mobile">
                                City
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('email')" class="hide-mobile">
                                Email
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('phone')" class="hide-mobile">
                                Phone
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in contacts" :key="item.uid" @click="selectProject(item)" class="table-row">
                              <td>{{ item.company }}</td>
                              <td>{{ item.name }}</td>
                              <td class="hide-mobile">{{ item.city }}</td>
                              <td class="hide-mobile">{{ item.email }}</td>
                              <td class="hide-mobile">{{ item.phone }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- .nk-block -->
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->

      <!-- Modal Content Code -->
      <div class="modal fade" tabindex="-1" id="modalDefault">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <a href="#" class="close" data-dismiss="modal" aria-label="Close">
              <em class="icon ni ni-cross"></em>
            </a>
            <div class="modal-header">
              <h5 class="modal-title">Contact Detail</h5>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger" v-if="errorMessage">
                {{ errorMessage }}
              </div>
              <form action="#" class="form-validate is-alter" novalidate="novalidate">
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Company</label>
                        <div class="form-control-wrap">
                          <input placeholder="min. 5 characters" type="text" class="form-control"
                            v-model="contactEntry.company" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Name</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.name" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Customer code</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.customer_code" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Street</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.street" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">ZIP</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.zip" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">City</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.city" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Phone</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.phone" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Mobile</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.mobile" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Email</label>
                        <div class="form-control-wrap">
                          <input type="email" class="form-control" v-model="contactEntry.email" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Country</label>
                        <div class="form-control-wrap">
                          <input type="text" class="form-control" v-model="contactEntry.country" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">

                    </div>
                    <div class="col-sm-4">

                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <label class="form-label" for="default-01">Comments</label>
                        <div class="form-control-wrap">
                          <textarea type="text" class="form-control" v-model="contactEntry.comments" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="form-group">
                  <button v-if="!projectLoading" type="button" class="btn btn-lg btn-primary"
                    @click="createOrUpdateContactAction(contactEntry)">
                    <span v-if="contactEntry.uid">Update contact</span>
                    <span v-if="!contactEntry.uid">Add contact</span>
                  </button>
                </div>
              </form>
            </div>
            <div class="modal-footer bg-light">
              <button v-if="!projectLoading" type="button" class="btn " @click="deleteContactAction(contactEntry)">
                <span v-if="contactEntry.uid">Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";
//import { EventBus } from '@/event-bus.js';
import { getContacts, createOrUpdateContact, deleteContact } from "@/services/contact.service";

export default {
  name: "Home",
  data() {
    return {
      fullLoader: null,
      contactEntry: {},
      mapSort: {},
      searchkey: "",
      db_result: [],
      errorMessage: '',
      projectLoading: false,
      rows: [],
    };
  },
  components: {
    Header,
    Navigation,
    Footer,
    //VGrid
  },

  computed: {
    companyName: function () {
      return this.$store.state.company;
    },
    loginName: function () {
      return this.$store.state.name;
    },
    projects_db: function () {
      return this.$store.state.projects;
    },
    loading_page: function () {
      return (
        this.$store.state.loading_page &&
        this.$store.state.loading_page == "setup"
      );
    },
    contacts() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.db_result.filter((contact) => {
          return (
            (contact.company &&
              contact.company
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1) ||
            (contact.name &&
              contact.name
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1) ||
            (contact.email &&
              contact.email
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1)
          );
        });
      }

      return this.db_result;
    },
  },
  mounted() {
    this.db_result = this.projects_db;
    window.scrollTo(0, 0);
  },
  created() {

    this.$store.commit("set_loading_page", "setup");
    this.showLoader();
    getContacts()
      .then((d) => {
        this.hideLoder();
        if (d.data.list) {
          this.$store.commit("set_contacts", d.data.list);
        }
        this.$store.commit("set_loading_page", null);
      })
      .catch((e) => {
        console.log("e", e)
        //this.$store.dispatch("api_error", e);
      });

  },
  watch: {
    "$store.state.contacts": function () {
      this.db_result = this.$store.state.contacts;
    },
  },
  methods: {
    selectProject: function (data) {
      this.contactEntry = data;
      this.errorMessage = ''
      $("#modalDefault").modal("show");
    },
    hideLoder: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },

    sortCol(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.db_result = this.db_result.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    addContact: function () {
      this.contactEntry = {};
      this.errorMessage = ''
      $("#modalDefault").modal("show");
    },
    deleteContactAction: function () {
      this.$swal({
        title: 'Are you sure to delete this entry?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.projectLoading = true;
          deleteContact(
            this.contactEntry
          ).then((d) => {
            this.projectLoading = false;

            if (d.data.error) {
              this.errorMessage = 'Save error'
            } else {
              $("#modalDefault").modal("hide");
              if (d.data.list) {
                this.$store.commit("set_contacts", d.data.list);
              }
            }
          });
        }
      });




    },
    createOrUpdateContactAction: function () {
      this.errorMessage = ''
      if (
        this.contactEntry.company &&
        this.contactEntry.company.length > 2
      ) {
        this.projectLoading = true;
        createOrUpdateContact(
          this.contactEntry
        ).then((d) => {
          this.projectLoading = false;

          if (d.data.error) {
            this.errorMessage = 'Save error'
          } else {
            $("#modalDefault").modal("hide");
            if (d.data.list) {
              this.$store.commit("set_contacts", d.data.list);
            }
          }
        });
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style scoped>
.revo-grid {
  height: 50%;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f5f5f5;
}
</style>
