<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title fw-normal">Image gallery</h2>
                    
                    </div>
                  </div>
                  <!-- .nk-block-head -->
                  <div id="my-drop-zone" class="imgupload-conteiner">
                    Select file or drop here
                  </div>

                  <p style="margin-top:20px;">Images can be stored here for <b>all projects</b>, which can be used in the report for <b>capter images</b>.</p>

                        <div class="col-sm-12" style="margin-top: 20px">
                          <table class="table table-sm">
                            <thead>
                              <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th></th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in imglib_list"
                                :key="item.uid"
                              >
                               <td>
                                  <div
                                  
                                  >
                          
                                    <a :href="pictureUrl(item.file)" target="_blank">
                                      <img
                                        :src="pictureUrl(item.file)"
                                        style="
                                          width: 120px;
                                          padding-bottom: 5px;
                                        "
                                      />
                                      </a>
                                    </div>
                              
                                </td>
                                <td>
                                  <div :data-id="item.uid" contenteditable="true" @blur="nameChanged(item)">{{ item.name }}</div>
                                  
                                  <div style="font-size: 11px; color: #999999">
                                    {{item.width}}x{{item.height}}px
                                  </div>
                                </td>
              
                                <td style="text-align:right;">
                                  <button class="btn btn-dim btn-sm btn-outline-light" @click="removeItem(item.uid, item.file)">Remove</button>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div class="alert alert-success" style="margin-top:50px;">Note: The image name can be changed by double-clicking on the name. </div>
                        </div>
            
                  <!-- .nk-block -->
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
import Vue from "vue";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";
import Dropzone from "dropzone";


import { getImgLib, removeImgLib, renameImgLib } from "@/services/reporting.service";

export default {
  name: "MobileApp",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      list: [],
      importSteps: 10,
      importFrom: 0,
      importTo: 0,
      importSuccess: false,
      importCount: 0,
      importNotFound: [],
    };
  },
  computed: {
    imglib_list: function () {
      return  this.$store.state.rp_imglib
    },
  },

  methods: {
    nameChanged: function(params) {
        console.log("name", params, $("*[data-id='"+params.uid+"'").html())

        const nname = $("*[data-id='"+params.uid+"'").html()

        if (nname.length > 2) {
           renameImgLib(params.uid, nname.trim()).then(d => {
             if (d.data && d.data.imglib) {
                this.$store.state.rp_imglib = d.data.imglib
             }
           
           })
        }
    },
    pictureUrl: function (file) {
      return (
        this.$apiBase +
        "/imagelib-file?file=" +
        file
      );
    },
    removeItem: function(uid, file) {
      removeImgLib(uid, file).then( d => {
        if (d.data && d.data.imglib) {
            this.$store.state.rp_imglib = d.data.imglib
        }
      })
    }
  },
  mounted() {

    const ref = this 
    getImgLib().then( d => {
      this.$store.state.rp_imglib = d.data.imglib
    })
    new Dropzone("#my-drop-zone", {
      paramName: "file", // The name that will be used to transfer the file
      maxFilesize: 4, // MB
      url: Vue.prototype.$apiBase + "/mobile/uploadChapterImage?project_uid=general&cat=image-lib",
      acceptedFiles: ".jpg,.png",  
      addRemoveLinks: false,   
      params: {  },
      disablePreviews: true,
      headers: {
        "x-access-token": this.$store.state.token, 
      },
      accept: function (file, done) {
        console.log(file);
        done();
      },
       success: function(file, response) {
         ref.$store.state.rp_imglib = response.data.imglib
       }
    });
  },
};
</script>

<style>
.imgupload-conteiner {
  border: 1px solid #cccccc;
  padding: 20px;
  text-align: center;
  background-color: #f5f6fa;
  cursor: pointer;
}

.imgupload-conteiner:hover {
  background-color: #798bff;
  color: #ffffff;
}
</style>
