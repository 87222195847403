import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const token = localStorage.getItem('token');
const initialState = token ?
  'success' :
  '';

const state = {
  status: initialState,
  user_role: null,
  user_uid: "",
  account_setup: false,
  loading_page: null,
  inspectionContent: null, 
  name: "",
  token: localStorage.getItem('token') || '',
  showSearch: false,
  projects: [],
  project: {},
  contacts: [],
  chapters_settings: [],
  project_member: null,
  userId: null, 
  login_system: null, 
  machines: [],
  insp_entry: null,
  account_role: {},
  iec_daaks: null,
  project_contacts: [], 
  permissions_member: null,
  permissions_users: null,
  company: "",
  rp_imglib_project: [],
  main_chapters: [],
  socket: null, 
  site: {},
  sites: [], 
  project_sites: [],
  project_collaborators: [],
  user: {}, 

  project_inspections: [], 
  //
  rp_project: {},
  rp_imglib: [],
  rp_inspections: [],
  rp_chapters_settings: [],
  rp_report_settings: {},

  rp_state_color: {
    'V': '#3BC600',
    'I': '#009dff',
    'E': '#0000ff',
    'P': '#FFAA1D',
    'PP': '#ff8000',
    'PPP': '#ff0000'
  },
  testProjectUser: [ "jochen.ziehmann.mo", "laurens.butin", "bernd.hoering", "m.stasiak", "lwimmer", "carsten.kaput"],
  testProject: {
    projectId: "00000000",
    _id: "00000000",
    uid: "00000000",
    projectnumber: "00K0000",
    projectname: "Test 8.2 Inspect",
    site: "Test-Windpark",
    siteId: "0",
    isTest: true,
    customer: "Test-Firma GmbH",
    customerId: "0",
    customerPostCode: "23456",
    customerCity: "Hamburg",
    customerStreet: "Berliner Straße 123",
    contractor: "8.2 Obst & Ziehmann GmbH",
    contractorId: "11",
    contractorPostCode: "20457",
    contractorCity: "Hamburg",
    contractorStreet: "Brandstwiete 4",
    machineManufacturer: "Vestas",
    machineManufacturerId: "24",
    machineType: "V52",
    machineTypeId: "44",
    contractDate: "2017-10-19",
    projectType: "Wiederkehrende Prüfung",
    contacts: [{
        _id: "1886013",
        name: "Hans Mustermann",
        role: "Betreiber",
        company: "Test-Firma GmbH",
        companyId: "0",
        postCode: "23456",
        city: "Hamburg",
        street: "Berliner Straße 123",
        phone: "+49 40 123456-7",
        pictureURL: "http://8p2-projekt.de/pdb/Upload/201710191109560.hans mustermann.jpg",
        mobile: "+49 175 12345677",
        email: "hans.mustermann@test.de",
        position: null,
      },
      {
        _id: "1886014",
        name: "Max Mustermann",
        role: "Schlüsselwart",
        company: "Test-Firma GmbH",
        companyId: "0",
        postCode: "23456",
        city: "Hamburg",
        street: "Berliner Straße 123",
        phone: "+49 40 123456-9",
        pictureURL: "http://8p2-projekt.de/pdb/Upload/201710191109440.max mustermann.jpg",
        mobile: "+49 175 12345678",
        email: null,
        position: null,
      },
    ],
    machines: [{
        _id: "10710",
        name: "V12345",
        machineManufacturer: "Vestas",
        machineTypeId: "44",
        machineType: "V52",
        nominalPower: "850",
        latitude: "52.8058",
        longitude: "9.22281",
        commissioningDate: null,
        lift: null,
        hubHeight: "65",
        rotorDiameter: "52",
        siteNumber: "1",
        fallProtectionSystem: null,
        towerType: null,
        antenna: "0",
      },
      {
        _id: "10711",
        name: "V12346",
        machineManufacturer: "Vestas",
        machineTypeId: "44",
        machineType: "V52",
        nominalPower: "850",
        latitude: "52.8051",
        longitude: "9.23129",
        commissioningDate: null,
        lift: null,
        hubHeight: "65",
        rotorDiameter: "52",
        siteNumber: "2",
        fallProtectionSystem: null,
        towerType: null,
        antenna: "0",
      },
      {
        _id: "10712",
        name: "V12347",
        machineManufacturer: "Vestas",
        machineTypeId: "44",
        machineType: "V52",
        nominalPower: "850",
        latitude: "52.8079",
        longitude: "9.218",
        commissioningDate: null,
        lift: null,
        hubHeight: "65",
        rotorDiameter: "52",
        siteNumber: "3",
        fallProtectionSystem: null,
        towerType: null,
        antenna: "0",
      },
      {
        _id: "10713",
        name: "V12348",
        machineManufacturer: "Vestas",
        machineTypeId: "44",
        machineType: "V52",
        nominalPower: "850",
        latitude: "52.8087",
        longitude: "9.2243",
        commissioningDate: null,
        lift: null,
        hubHeight: "65",
        rotorDiameter: "52",
        siteNumber: "4",
        fallProtectionSystem: null,
        towerType: null,
        antenna: "0",
      },
    ],
    sites: [{
        _id: 1505510,
        name: "Park B",
        alias: "",
        country: "",
        postCode: "27796",
        city: "Parkcity",
        street: "",
        longitude: 0,
        latitude: 0,
        installedPower: "",
        technology: "",
        machines: [{
            _id: 1505650,
            serialNumber: "70925",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "06",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-10-10",
            lift: "2021-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70925",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 1505622,
            serialNumber: "70923",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "04",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-10-22",
            lift: "2021-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70923",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 1505594,
            serialNumber: "70922",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "03",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-09-27",
            lift: "2021-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70922",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 1505566,
            serialNumber: "70921",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "02",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-09-26",
            lift: "2021-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70921",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 1505538,
            serialNumber: "70920",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "01",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-10-30",
            lift: "2021-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70920",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 2966627,
            serialNumber: "70926",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "07",
            longitude: 0,
            latitude: 0,
            commissioningDate: null,
            lift: null,
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70926",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 2966596,
            serialNumber: "70924",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "05",
            longitude: 0,
            latitude: 0,
            commissioningDate: null,
            lift: null,
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70924",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
        ],
        machinesCount: 7,
      },
      {
        _id: 1505671,
        name: "Park A",
        alias: "",
        country: "",
        postCode: "26209",
        city: "Parkcity",
        street: "",
        longitude: 8.3348,
        latitude: 53.0496,
        installedPower: "",
        technology: "",
        machines: [{
            _id: 1505727,
            serialNumber: "70943",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "02",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-11-05",
            lift: "2021-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70943",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
          {
            _id: 1505699,
            serialNumber: "70942",
            manufacturer: "Enercon",
            type: "E-66/18.70",
            alias: "01",
            longitude: 0,
            latitude: 0,
            commissioningDate: "2002-11-07",
            lift: "2020-11-30",
            hubHeight: 98,
            rotorDiameter: 70,
            towerType: "",
            name: "70942",
            machineManufacturer: "Enercon",
            machineType: "E-66/18.70",
          },
        ],
        machinesCount: 2,
      },
    ],
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}