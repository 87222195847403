import * as axios from 'axios';

function getSiteList() {
    
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/sites`;
    return axios.get(url, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function getSite(uid) {
    
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/sites/`+ uid;
    return axios.get(url, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            if (x.status == 'error') {
                return Promise.reject(x);
            }
            return x
        });
}

function updateSite(site, deleteMachines) {
    const data = {
        site: site,
        deleteMachines: deleteMachines
    }

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/update-site`;
    return axios.post(url, data, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            return x
        });
}


function deleteSite(site) {
    const data = {
        site_uid: site.uid 
    }

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/delete-site`;
    return axios.post(url, data, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            return x
        });
}

export {
    getSite,
    getSiteList,
    updateSite,
    deleteSite
}