<template>
  <div class="modal fade" tabindex="-1" id="modalDefault">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <em class="icon ni ni-cross"></em>
        </a>
        <div class="modal-header">
          <h5 class="modal-title">Settings</h5>
        </div>
        <div class="modal-body">
          <form
            action="#"
            class="form-validate is-alter"
            novalidate="novalidate"
          >
            <div class="form-group">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-01"
                      >Localisation picture</label
                    >
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          v-model="chapterEntry.chapterimage"
                        />
                        <label class="custom-control-label" for="customCheck1"
                          >Chapter with image</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group" v-if="chapterEntry.chapterimage">
                    <label class="form-label" for="default-01">&nbsp;</label>
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck2"
                          v-model="chapterEntry.chapterincludes"
                        />
                        <label class="custom-control-label" for="customCheck2"
                          >includes all following chapters</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" v-if="chapterEntry.chapterimage">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <select
                    class="form-control"
                    v-model="chapterEntry.chapterfile"
                  >
                    <option
                      :value="item.file"
                      v-for="item in imglib_list"
                      :key="item.uid"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6">
                  <div class="form-control-wrap">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck9"
                        v-model="chapterEntry.chapterV"
                      />
                      <label class="custom-control-label" for="customCheck9"
                        >show V (OK) on image</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-01">Format</label>
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck3"
                          v-model="chapterEntry.newpage"
                        />
                        <label class="custom-control-label" for="customCheck3"
                          >Chapter on new page</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <!--
                  <div class="form-group">
                    <label class="form-label" for="default-01">&nbsp;</label>
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck9"
                          v-model="chapterEntry.newpagepictures"
                        />
                        <label class="custom-control-label" for="customCheck9"
                          >Pictures on new page</label
                        >
                      </div>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
            <div class="form-group" style="margin-top: 30px">
              <button
                type="button"
                style="text-align:center;width: 100%;"
                class=" btn-lg btn-primary"
                @click="updateSettings"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getChapterSettings,
  saveChapterSettings,
} from "@/services/reporting-v3.service";

export default {
  props: ["chapterId", "projectNumber", "inspectionEntry", "projectUid"],
  data() {
    return {
      chapterEntry: {},
    };
  },
  computed: {
    imglib_list: function () {
      return this.$store.state.rp_imglib;
    },
  },
  mounted() {
    $("#modalDefault").modal("show");

    const ref = this;

    $("#modalDefault").on("hidden.bs.modal", function () {
      ref.$emit("dialogClosed");
    });

    this.chapterEntry = {
      chapterimage: false,
      chapterV: false,
      newpage: false,
    };

    let chapter_uid = "";

    if (this.chapterId.chapter && this.chapterId.chapter.length > 0) {
      chapter_uid += this.chapterId.chapter + "-";
    }

    getChapterSettings(
      this.projectNumber,
      this.inspectionEntry.uid,
      chapter_uid+""+this.chapterId.level + "-" + this.chapterId.title.text,
      this.projectUid
    ).then((d) => {
      if (d) {
        if (d.data && d.data.imglib) {

          this.$store.state.rp_imglib = []

          for (let i = 0; i < d.data.projectlib.length; i++) {
            this.$store.state.rp_imglib.push({
              uid: d.data.projectlib[i].uid,
              name: d.data.projectlib[i].name,
              file: d.data.projectlib[i].file,
              width: d.data.projectlib[i].width,
              height: d.data.projectlib[i].height,
              cat: 'projectlib'
            });
          }

          for (let i = 0; i < d.data.imglib.length; i++) {
            this.$store.state.rp_imglib.push({
              uid: d.data.imglib[i].uid,
              name: d.data.imglib[i].name,
              file: d.data.imglib[i].file,
              width: d.data.imglib[i].width,
              height: d.data.imglib[i].height,
              cat: 'image-lib' 
            });
          }
 
          //this.$store.state.rp_imglib = d.data.imglib;
        }

        if (d.data && d.data.chapter) {
          this.chapterEntry = d.data.chapter;
        }
      }
    });

    // ------
  },
  methods: {
    updateSettings: async function () {


      // Chapter UID

      let chapter_uid = "";

      if (this.chapterId.chapter && this.chapterId.chapter.length > 0) {
        chapter_uid += this.chapterId.chapter + "-";
      }

      if (this.chapterEntry.chapterfile) {
        console.log("ja ist ein file",this.chapterEntry)
        for (let n in this.$store.state.rp_imglib) {
          if (this.$store.state.rp_imglib[n].file == this.chapterEntry.chapterfile) {
            this.chapterEntry.file_cat = this.$store.state.rp_imglib[n].cat
            break;
          }
        }
      }

      await saveChapterSettings(
        this.projectNumber,
        this.inspectionEntry.uid,
        chapter_uid +
          "" +
          this.chapterId.level +
          "-" +
          this.chapterId.title.text,
        this.chapterEntry
      ).then((d) => {
        this.$store.state.rp_chapters_settings = d.data.chapters_settings;

        this.$store.state.chapters_settings = d.data.chapters_settings;
        $("#modalDefault").modal("hide");
      });
    },
  },
};
</script>

<style>
</style>