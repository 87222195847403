<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title fw-normal">Report Settings</h2>
                    </div>
                  </div>
                  <!-- .nk-block-head -->
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#tabItem1"
                        >Footer</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem2"
                        >Header</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem3"
                        >Color</a
                      >
                    </li>
            
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="tabItem1">
                      <div class="card card-preview">
                        <ReportFooter></ReportFooter>
                      </div>
                    </div>
                    <div class="tab-pane" id="tabItem2">
                      <div class="card card-preview">
                        <ReportHeader></ReportHeader>
                      </div>
                    </div>
                    <div class="tab-pane" id="tabItem3">
                      <div class="card card-preview">
                        <ReportColor></ReportColor>
                      </div>
                    </div>
                    <div class="tab-pane" id="tabItem4">
                      <p>contnet</p>
                    </div>
                  </div>

                  <!-- .nk-block -->
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
//import Vue from "vue";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

import ReportFooter from "../../components/ReportFooter.vue";
import ReportHeader from "../../components/ReportHeader.vue";
import ReportColor from "../../components/ReportColor.vue";






import {
  getMainReportSettings
} from "@/services/reporting.service";

export default {
  name: "MobileApp",
  components: {
    Header,
    Navigation,
    Footer,
    ReportFooter,
    ReportHeader,
    ReportColor,

  },
  data() {
    return {
      selectedColor: '#ff0000' // Standardfarbe
    };
  },
  computed: {

  },

  methods: {
    
    pictureUrl: function (file) {
      return this.$apiBase + "/collection-file?file=" + file;
    },
   
  },
  mounted() {
    //const ref = this
    getMainReportSettings().then((d) => {
      if (d.data) {
        console.log("d", d.data)
        delete d.data._id
        delete d.data._v
        this.$store.state.rp_report_settings = d.data
    
      }
    });
  },
};
</script>

<style>
.imgupload-conteiner {
  border: 1px solid #cccccc;
  padding: 20px;
  text-align: center;
  background-color: #f5f6fa;
  cursor: pointer;
}

.imgupload-conteiner:hover {
  background-color: #798bff;
  color: #ffffff;
}
</style>
