<template>
  <div>
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap">
          <Header v-show="!loading_page" />
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container wide-xl">
              <div class="nk-content-inner">
                <Navigation v-show="!loading_page" />
                <!--
                <div class="nk-content-body" v-show="loading_page">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                -->
                <div class="nk-content-body" v-show="!loading_page">
                  <div class="nk-content-wrap">
                    <!-- .nk-block-head -->
                    <div class="nk-block nk-block-lg">
                      <div class="nk-block-head">
                        <div class="nk-block-head-content">
                          <h4 class="nk-block-title">Sites</h4>
                        </div>
                      </div>
                      <div class="btn btn-secondary btn-dim btn-sm" @click="addContact()" style="margin: 10px 0 20px 0">
                        <span>Add site</span>
                      </div>
                      <div class="form-control-wrap" style="margin-bottom: 20px">
                        <div class="input-group">
                          <input v-model="searchkey" type="text" class="form-control" placeholder="Filter" />
                        </div>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th scope="col" style="cursor: pointer" @click="sortCol('name')">
                                Name
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('siteCity')">
                                City
                              </th>
                              <th scope="col" style="cursor: pointer" @click="sortCol('siteTechnology')" class="hide-mobile">
                                Technology
                              </th>
                              <th scope="col" style="cursor: pointer" class="hide-mobile">
                                Machines
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in sites" :key="item.uid" @click="selectSite(item)" class="table-row">
                              <td>{{ item.name }}</td>
                              <td>{{ item.siteCity }}</td>
                              <td class="hide-mobile">{{ item.siteTechnology }}</td>
                              <td class="hide-mobile">{{ item.machines.length }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- .nk-block -->
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->

 
    </div>
  </div>
</template>

<script>
//import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";
//import { EventBus } from '@/event-bus.js';
import { getSiteList } from "@/services/site.service";

export default {
  name: "Home",
  data() {
    return {
      fullLoader: null,
      contactEntry: {},
      mapSort: {},
      searchkey: "",
      db_result: [],
      errorMessage: '',
      projectLoading: false,
      rows: [],
    };
  },
  components: {
    Header,
    Navigation,
    Footer,
    //VGrid
  },

  computed: {
    companyName: function () {
      return this.$store.state.company;
    },
    loginName: function () {
      return this.$store.state.name;
    },

    loading_page: function () {
      return (
        this.$store.state.loading_page &&
        this.$store.state.loading_page == "setup"
      );
    },
    sites() {
      if (this.searchkey && this.searchkey.length > 2) {
        return this.db_result.filter((site) => {
          return (
            (site.name &&
            site.name
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1) ||
            (site.zip &&
            site.zip
                .toLowerCase()
                .indexOf(this.searchkey.toLowerCase()) !== -1)
          );
        });
      }

      return this.db_result;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.$store.commit("set_loading_page", "setup");
    this.showLoader();
    getSiteList()
      .then((d) => {
        this.hideLoder();
        if (d.data.list) {
          this.$store.commit("set_sites", d.data.list);
        }
        this.$store.commit("set_loading_page", null);
      })
      .catch((e) => {
        console.log("e", e)
        //this.$store.dispatch("api_error", e);
      });

  },
  watch: {
    "$store.state.sites": function () {
      this.db_result = this.$store.state.sites;
    },
  },
  methods: {
    selectSite: function (data) {
      this.$router.push({ name: "site-detail", params: { uid: data.uid } });
      this.errorMessage = ''
    },
    hideLoder: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },

    sortCol(val) {
      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.db_result = this.db_result.sort(
        this.sortByKey(val, this.mapSort[val])
      );
    },
    addContact: function () {
      this.errorMessage = ''
      this.$router.push({ name: "site-detail", params: { uid: 'new' } });
    },

    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style scoped>
.revo-grid {
  height: 50%;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: #f5f5f5;
}
</style>
