export default {
    auth_request(state){
        state.status = 'loading'
      },
      auth_success(state, payload) {
        state.status = 'success'
        state.token = payload.token
        state.user = payload.user
      },
      auth_error(state){
        state.status = 'error'
      },
      set_projects(state, payload){
        state.projects = payload
      },
      set_contacts(state, payload){
        state.contacts = payload
      },
      set_sites(state, payload){
        state.sites = payload
      },
      set_loading_page(state, payload) {
        state.loading_page = payload
      },
      logout(state){
        localStorage.removeItem('token')
        state.projects = null
        state.status = ''
        state.account_setup = false
        state.token = ''
      }
}
