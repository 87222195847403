<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title fw-normal">Inspect App</h2>
                    </div>
                  </div>
                  <!-- .nk-block-head -->

                  <div v-if="isFirefox" class="alert alert-warning alert-icon">
                    <em class="icon ni ni-alert-circle"></em> The Inspect app is
                    not compatible with your browser<br />
                    <strong>Please use Chrome.</strong>
                  </div>
                  <div class="nk-block">
                    <div class="support-topic-item card card-bordered">
                      <a class="support-topic-block card-inner" href="https://inspect-app-v3.8p2.de" target="_blank">
                        <div class="support-topic-media">
                          <span class="material-icons md-48" style="font-size: 80px">open_in_browser</span>
                        </div>
                        <div class="support-topic-context">
                          <h5 class="support-topic-title title">8.2 Inspect for Browser</h5>
                          <!-- div class="support-topic-info">You can find all of the questions and answers of general purpose.</div>-->
                        </div>
                        <div class="support-topic-action">
                          <em class="icon ni ni-chevron-right"></em>
                        </div>
                      </a>
                    </div>
                    <!-- .support-topic-item -->
                    <div id="faqs" class="accordion">
                      <div class="accordion-item">
                        <a href="#" class="accordion-head collapsed" data-toggle="collapse" data-target="#faq-q1"
                          aria-expanded="false">
                          <h6 class="title">Info</h6>
                          <span class="accordion-icon"></span>
                        </a>
                        <div class="accordion-body collapse" id="faq-q1" data-parent="#faqs" style="">
                          <div class="accordion-inner">
                            <ul>
                              <li style="padding-bottom: 20px;">
                                You can find all information about updates <a
                                  href="https://inspect-app.notion.site/Updates-1dea2e0ef4bc4afca58b386af0008e89"
                                  target="_blank">here</a>.<br>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="nk-block">
                    <div class="support-topic-item card card-bordered">
                      <a class="support-topic-block card-inner"
                        href="https://play.google.com/store/apps/details?id=de.achtpunktzwei.inspect" target="_blank">
                        <div class="support-topic-media">
                          <span class="material-icons md-48" style="font-size: 80px">adb</span>
                        </div>
                        <div class="support-topic-context">
                          <h5 class="support-topic-title title">
                            8.2 Inspect for Android
                          </h5>
                          <div class="support-topic-info">
                            The app for android can be found in the Google Play Store
                          </div>
                        </div>
                      </a>
                    </div>
                    <!-- .support-topic-item -->
                  </div>
                  <div class="nk-block">
                    <div class="support-topic-item card card-bordered">
                      <a class="support-topic-block card-inner"
                        href="https://testflight.apple.com/join/ETqVepI6" target="_blank">
                        <div class="support-topic-media">
                          <span class="material-icons md-48" style="font-size: 80px">apple</span>
                        </div>
                        <div class="support-topic-context">
                          <h5 class="support-topic-title title">
                            8.2 Inspect for iOS
                          </h5>
                          <div class="support-topic-info">
                            The app for iOS is currently available via TestFlight 

                          </div>
                        </div>
                      </a>
                    </div>


                    <!-- .support-topic-item -->





                  </div>
                  <!-- .nk-block -->
                  <div class="nk-block nk-block-lg">
                    <div class="card card-bordered border-primary">
                      <div class="card-inner">
                        <div class="nk-cta">
                          <div class="nk-cta-block">
                            <div class="nk-cta-img">
                              <em class="icon icon-circle ni ni-msg"></em>
                            </div>
                            <div class="nk-cta-text">
                              <p>
                                If have any questions please contact our
                                support.
                              </p>
                            </div>
                          </div>
                          <div class="nk-cta-action">
                            <a href="mailto:inspect@8p2.de" class="btn btn-primary">Contact Us</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .card -->
                  </div>
                  <!-- .nk-block -->
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "WebApp",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      isFirefox: null,
    };
  },
  mounted() {
    this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  },
};
</script>

<style></style>
