<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head">
                      <div class="nk-block-head-content">
                        <h4 class="nk-block-title">Sites</h4>
                      </div>
                    </div>
                  </div>
                  <div class="btn btn-secondary btn-dim btn-sm" @click="$router.go(-1)" style="margin: 10px 0 20px 0">
                    <span>Back to overview</span>
                  </div>
                  <!-- .nk-block-head -->
                  <div class="tab-content">
                    <!-- Project Detail -->
                    <div class="tab-pane active" id="tabItem9">
                      <div class="card card-preview">
                        <div class="card-inner">
                          <div class="preview-block">
                            <div class="card card-preview">
                              <div class="card-inner">
                                <div class="preview-block">
                                  <div class="row gy-4">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">Name</label>
                                        <div class="form-control-wrap">
                                          <input type="text" class="form-control" v-model="siteItem.name" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">Technology</label>
                                        <div class="form-control-wrap">
                                          <input type="text" class="form-control" v-model="siteItem.siteTechnology" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row gy-4">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">ZIP code</label>
                                        <div class="form-control-wrap">
                                          <input type="text" class="form-control" v-model="siteItem.sitePostCode" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">City</label>
                                        <div class="form-control-wrap">
                                          <input type="text" class="form-control" v-model="siteItem.siteCity" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row gy-4">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">Country</label>
                                        <div class="form-control-wrap">
                                          <input type="text" class="form-control" v-model="siteItem.siteCountry" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-6"></div>
                                  </div>
                                  <div class="row gy-4">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">Latitude</label>
                                        <div class="form-control-wrap">
                                          <input type="number" class="form-control" v-model="siteItem.siteLatitude" />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label class="form-label" for="default-01">Longitude</label>
                                        <div class="form-control-wrap">
                                          <input type="number" class="form-control" v-model="siteItem.siteLongitude" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group">
                                    <div class="col-sm-12" style="margin-top: 30px">
                                      <span class="preview-title-lg overline-title">Machines
                                        <span v-if="siteItem && siteItem.machines">({{ siteItem.machines.length
                                          }})</span></span>
                                      <div class="btn btn-secondary btn-dim btn-sm" v-if="canSave" @click="
                                            selectMachine({ machineDb: '' }, siteItem)
                                            " style="margin: 10px 0">
                                        <span>Add machine</span>
                                      </div>
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Serial number</th>
                                            <th scope="col" class="hide-mobile">
                                              Manufacturer
                                            </th>
                                            <th scope="col" class="hide-mobile">
                                              Type
                                            </th>
                                            <th></th>
                                            <th></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="machine in siteItem.machines" :key="machine._id">
                                            <td>{{ machine.siteNumber }}</td>
                                            <td class="hide-mobile">
                                              {{ machine.machineManufacturer }}
                                            </td>
                                            <td class="hide-mobile">
                                              {{ machine.machineType }}
                                            </td>
                                            <td>
                                              <div class="btn btn-outline-primary" style="
                                                border: none;
                                                padding: 3px 7px;
                                              " @click="
                                            selectMachine(machine, siteItem)
                                            ">
                                                Details
                                              </div>
                                            </td>
                                            <td>
                                              <div class="btn btn-outline-primary" style="
                                                border: none;
                                                padding: 3px 7px;
                                              " @click="
                                            duplicateMachine(machine, siteItem)
                                            ">
                                                <em class="icon ni ni-folders"></em>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr class="preview-hr" />
                            <div class="form-group">
                              <div v-if="infoSaved" class="alert alert-success alert-icon">
                                <em class="icon ni ni-check-circle"></em> Saved
                              </div>
                              <button type="submit" v-if="canSave" class="btn btn-lg btn-primary"
                                @click="saveProject()">
                                Save site
                              </button>
                              <div style="float: right" v-if="canDelete" class="btn btn-sm btn-outline-light"
                                @click="deleteSiteAction()">
                                Delete site
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Project Detail -->
                    </div>
                  </div>

                  <!-- Modal Content Code -->
                  <div class="modal fade" id="modalDefault">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Machine</h5>
                        </div>
                        <div class="modal-body">
                          <div class="form-group" v-show="canSave">
                            <label class="form-label" for="default-01">Machines list</label>
                            <select id="cbMachineDb" v-model="machineEntry.machineDb" data-ui="lg" data-search="on">
                              <option :value="maEntry.value" v-for="maEntry in machines" :key="maEntry.uid">
                                {{ maEntry.text }}
                              </option>
                            </select>
                          </div>
                          <hr class="preview-hr" v-show="canSave" />
                          <form action="#" class="form-validate is-alter" novalidate="novalidate">
                            <div class="form-group">
                              <div class="row gy-4">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Serial number</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="machineEntry.siteNumber" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Name</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="machineEntry.name" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row gy-4">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Manufacturer</label>
                                    <div class="form-control-wrap">
                                      <input type="text" :readonly="canSave" class="form-control"
                                        v-model="machineEntry.machineManufacturer" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Type</label>
                                    <div class="form-control-wrap">
                                      <input type="text" :readonly="canSave" class="form-control"
                                        v-model="machineEntry.machineType" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row gy-4">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Nominal power</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="machineEntry.nominalPower" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Commissioning date</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" placeholder="YYYY-MM-DD"
                                        v-model="machineEntry.commissioningDate" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row gy-4">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Hub height</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="machineEntry.hubHeight" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Rotor Diameter</label>
                                    <div class="form-control-wrap">
                                      <input type="text" class="form-control" v-model="machineEntry.rotorDiameter" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row gy-4">
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Latitude</label>
                                    <div class="form-control-wrap">
                                      <input type="number" class="form-control" v-model="machineEntry.latitude" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <div class="form-group">
                                    <label class="form-label" for="default-01">Longitude</label>
                                    <div class="form-control-wrap">
                                      <input type="number" class="form-control" v-model="machineEntry.longitude" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="form-group">
                              <button type="button"  class="btn btn-lg btn-primary"
                                @click="saveMachine()">
                                Save machine
                              </button>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer bg-light">
                          <div class="btn btn-outline-light"  @click="deleteMachine(machineEntry)">
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import {
  getSite,
  updateSite,
  deleteSite,
} from "@/services/site.service";

export default {
  name: "MobileApp",
  components: {
    Header,
    Navigation
  },
  mounted() {
    window.scrollTo(0, 0);

    this.siteUid = this.$route.params.uid

    getSite(this.siteUid).then((data) => {

      if (data.data.status == "error") {
        return this.$router.push("/sites");
      }

      if (this.siteUid == "new") {
        this.$store.state.site = {
          name: "",
          sitePostCode: "",
          siteCity: "",
          siteLongitude: "",
          siteLatitude: "",
          siteInstalledPower: "",
          siteTechnology: "",
          machines: [],
        };
      } else {
        this.$store.state.site = data.data.site;
      }

      this.$store.state.machines = data.data.machines;
      this.$store.state.user_uid = data.data.user_uid;

    });

    $("#cbMachineDb").select2();

    let ref = this;

    $("#cbMachineDb").change(function () {
      const uid = $("#cbMachineDb").val();
      let entry = ref.getMachineDb(uid);

      if (entry) {
        ref.machineEntry.machineType = entry.machineType;
        ref.machineEntry.machineManufacturer = entry.machineManufacturer;
        ref.machineEntry.nominalPower = entry.nominalPower;
        ref.machineEntry.rotorDiameter = entry.rotorDiameter;
        ref.machineEntry.hubHeight = entry.hubHeight;
        ref.machineEntry.machineDb = uid;
        ref.$forceUpdate();
      }
    });
  },
  computed: {
    siteItem: function () {
      return this.$store.state.site;
    },
    machines: function () {
      return this.$store.state.machines;
    },
    canDelete: function () {
      return this.siteItem.uid && this.siteItem.uid.length > 5;
    },
    canSave: function () {
      return true
    },
  },
  data() {
    return {
      machineEntry: {},
      deleteMachines: [], 
      fullLoader: null,
      infoSaved: false,
      siteUid: "new",
    };
  },
  methods: {
    showSaved: function () {
      this.infoSaved = true;
      let ref = this;
      setTimeout(function () {
        ref.infoSaved = false;
      }, 2000);
    },
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
    uuid4() {
      const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
      const view = new DataView(new ArrayBuffer(16)); /// Create a view backed by a 16-byte buffer
      crypto.getRandomValues(new Uint8Array(view.buffer)); /// Fill the buffer with random data
      view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40); /// Patch the 6th byte to reflect a version 4 UUID
      view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80); /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
      return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(
        view.getUint16(6),
        4
      )}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(
        view.getUint16(14),
        1
      )}`; /// Compile the canonical textual form from the array data
    },
    getMachineDb: function (uid) {
      for (let i = 0; i < this.machines.length; i++) {
        if (this.machines[i].value == uid) {
          return this.machines[i].entry;
        }
      }
      return {};
    },
    duplicateMachine: function (data, site) {
      this.machineEntry = JSON.parse(JSON.stringify(data));
      this.siteEntry = site;

      this.machineEntry.uid = this.uuid4();
      this.machineEntry._id = this.machineEntry.uid;
      this.siteEntry.machines.push(this.machineEntry);
    },
    selectMachine: function (data, site) {
      this.machineEntry = data; //JSON.parse(JSON.stringify(data));
      this.siteEntry = site;

      if (this.machineEntry.machineDb) {
        // $("#cbMachineDb").select2("val", this.machineEntry.machineDb);
        console.log("this.machineEntry.machineDb", this.machineEntry.machineDb);
      }

      $("#modalDefault").modal("show");
    },
    saveMachine: function () {
      if (this.machineEntry.uid) {
        for (let k = 0; k < this.siteItem.machines.length; k++) {
          if (this.siteItem.machines[k].uid == this.machineEntry.uid) {
            this.siteItem.machines[k] = this.machineEntry;
          }
        }
        $("#modalDefault").modal("hide");
      } else {
        (this.machineEntry.uid = this.uuid4()),
          (this.machineEntry._id = this.machineEntry.uid);
        this.siteItem.machines.push(this.machineEntry);
        console.log("this.site.machines", this.siteItem.machines);
        $("#modalDefault").modal("hide");
      }
    },
    saveProject: function () {
      if (this.siteItem.name.length < 2) {
        return this.$swal({
          title: "Please enter a name",
          icon: "error",
        });
      }

      this.showLoader();
      updateSite(this.siteItem, this.deleteMachines).then((d) => {
        this.hideLoader();
        this.showSaved();
        this.$store.commit("set_sites", d.data.list);
      });
    },
    deleteSiteAction() {
      this.$swal({
        title: "Are you sure to delete this site ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.showLoader();

          deleteSite(this.siteItem).then((d) => {
            this.hideLoader();

            if (d.data.list) {
              this.$store.commit("set_sites", d.data.list);
            }

           this.$router.go(-1) 
          });
        }
      });
    },


    deleteMachine(machine) {
      this.$swal({
        title: 'Are you sure to delete "' + machine.name + '" ?',
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("weg damit", machine);
          $("#modalDefault").modal("hide");

          for (let k = 0; k < this.siteItem.machines.length; k++) {
            if (this.siteItem.machines[k].uid == machine.uid) {
              this.deleteMachines.push(machine.uid);
              this.siteItem.machines.splice(k, 1);
              return;
            }
          }
        }
      });
    },
  },
};
</script>

<style></style>
