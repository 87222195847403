//import { fetchListData } from '../api/api'

import Vue from 'vue';
import router from '../router';

export default {

  logout ({commit}) {
    commit('logout')
    localStorage.removeItem('token')
  },
  api_error ({ commit }, error) {
    console.log("api_error", error)
    if (Vue.config.productionTip) {
      commit('logout')
      router.push({ name: 'login' })  
    }
  },

  
}
