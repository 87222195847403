<template>
    <div>

        <div class="nk-aside" data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg"
            data-toggle-body="true">
            <div class="nk-sidebar-menu" data-simplebar>
                <!-- Menu -->
                <ul class="nk-menu">
                    <li class="nk-menu-heading">
                        <h6 class="overline-title">Menu</h6>
                    </li>
                    <li class="nk-menu-item">
                        <router-link :to="{ name: 'dashboard', params: {} }" tag="a" class="nk-menu-link">
                            <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                            <span class="nk-menu-text">Projects</span>
                        </router-link>
                    </li>
                    <li class="nk-menu-item">
                        <router-link :to="{ name: 'web-app', params: {} }" tag="a" class="nk-menu-link">
                            <span class="nk-menu-icon">
                                <span class="material-icons md-48">web</span>
                            </span>
                            <span class="nk-menu-text">App</span>
                        </router-link>
                    </li>
                    <li class="nk-menu-item">
                        <router-link :to="{ name: 'imagelib', params: {} }" tag="a" class="nk-menu-link">
                            <span class="nk-menu-icon">
                                <span class="nk-menu-icon"><em class="icon ni ni-upload-cloud"></em></span>
                            </span>
                            <span class="nk-menu-text">Image gallery</span>
                        </router-link>
                    </li>
                    <li class="nk-menu-item">
                        <router-link :to="{ name: 'reportsettings', params: {} }" tag="a" class="nk-menu-link">
                            <span class="nk-menu-icon">
                                <span class="nk-menu-icon"><em class="icon ni ni-setting"></em></span>
                            </span>
                            <span class="nk-menu-text">Report Settings</span>
                        </router-link>
                    </li>

                    <li class="nk-menu-item" v-if="account_role_permissions">
                        <router-link :to="{ name: 'permissions', params: {} }" tag="a" class="nk-menu-link">
                            <span class="nk-menu-icon">
                                <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                            </span>
                            <span class="nk-menu-text">Permissions</span>
                        </router-link>
                    </li>

                    <li class="nk-menu-item" v-if="bookings_role_permissions">
                        <router-link :to="{ name: 'bookinghistory', params: {} }" tag="a" class="nk-menu-link">
                            <span class="nk-menu-icon">
                                <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                            </span>
                            <span class="nk-menu-text">Booking History</span>
                        </router-link>
                    </li>

                    <li class="nk-menu-item">
                        <a href="https://inspect-app.notion.site/9cf8660a9db447a0ac25ae1197efb075?v=42a978a9371c477daeef1004bb97cdd0"
                            target="_blank" class="nk-menu-link">
                            <span class="nk-menu-icon">
                                <span class="material-icons md-48">support</span>
                            </span>
                            <span class="nk-menu-text">Help Center</span>
                        </a>
                    </li>


                    <li class="nk-menu-item" v-if="canAddProject"><a href="#" class="nk-menu-link"><span class="nk-menu-icon"><em
                                    class="icon ni ni-user-list"></em></span><span class="nk-menu-text">Master
                                data</span></a>
                        <ul class="nk-menu-sub">
                            <li class="nk-menu-item">
                                <router-link :to="{ name: 'contacts', params: {} }" tag="a" class="nk-menu-link">
                                    <span class="nk-menu-text">Contacts</span>
                                </router-link>
                            </li>
                            <!--
                            <li class="nk-menu-item">
                                <router-link :to="{ name: 'contacts', params: {} }" tag="a" class="nk-menu-link">
                                    <span class="nk-menu-text">User</span>
                                </router-link>
                            </li>  
                            -->
                            <li class="nk-menu-item">
                                <router-link :to="{ name: 'sites', params: {} }" tag="a" class="nk-menu-link">
                                    <span class="nk-menu-text">Sites</span>
                                </router-link>
                            </li>
                        </ul>
                    </li>

                </ul>

            </div><!-- .nk-sidebar-menu -->
            <div class="nk-aside-close">
                <a href="#" class="toggle" data-target="sideNav"><em class="icon ni ni-cross"></em></a>
            </div><!-- .nk-aside-close -->
        </div><!-- .nk-aside -->
    </div>

</template>

<script>


import { EventBus } from '@/event-bus.js';

export default {
    name: 'Home',
    components: {

    },
    computed: {
        account_role_permissions: function () {
            return this.$store.state.account_role.permissions == 'write';
        },
        bookings_role_permissions: function () {
            return this.$store.state.account_role.bookings == "write";
        },
        canAddProject: function () {
            return this.$store.state.login_system == "inspect";
        },
    },
    created() {

        EventBus.$off('toggle:mobile:menu')
        EventBus.$on('toggle:mobile:menu', this.toggleMobileMenu);
    },
    methods: {
        toggleMobileMenu: function () {
            $(".nk-aside").addClass("mobile-menu")
            if ($(".nk-aside").hasClass(("content-active"))) {
                $(".nk-aside").removeClass("content-active")
            }
            else {
                $(".nk-aside").addClass("content-active")
            }
        }
    },
}
</script>

<style></style>
