<template>
  <div class="modal fade" tabindex="-1" id="modalDefault">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <em class="icon ni ni-cross"></em>
        </a>
        <div class="modal-header">
          <h5 class="modal-title">Localisation Picture</h5>
        </div>

        <div class="modal-body">
          <div id="konva-container"></div>
          <div class="resultContainer" style="margin-top: 50px">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Nr</th>
                  <th></th>
                  <th>Result</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in findingsList" :key="item.id">
                  <td>
                    {{ item.pos }}
                  </td>
                  <td>
                    <span
                      class="badge badge-primary"
                      v-bind:style="stateColorStyle(item.state)"
                      >{{ item.state }}</span
                    >
                  </td>
                  <td>
                    {{ item.text }}
                  </td>
                  <td>
                    <div
                      class="btn btn-outline-light btn-sm"
                      @click="resetMarker(item)"
                    >
                      Marker reset
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer bg-light">
          <div class="btn btn-sm" data-dismiss="modal" v-if="!saving">
            Cancel
          </div>
          <div v-if="saving">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="btn btn-primary" @click="saveImage()" v-if="!saving">
            <span>SAVE IMAGE</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getChapterSettings,
  updateChapterMarker,
} from "@/services/reporting-v3.service";

export default {
  props: [
    "chapterId",
    "projectNumber",
    "inspectionEntry",
    "findingsForChapterAll",
    "findingsForChapter",
    "projectUid",
  ],
  data() {
    return {
      remarkList: [],
      remarkStage: null,
      showChapterSettings: false,
      markerItems: [],
      chapterEntry: {},
      saving: false,
      fullLoader: null,
      findingsList: [],
    };
  },
  computed: {
    /*
    findingsList: function () {
      let list = [];

      const lll = this.chapterEntry.chapterincludes
        ? this.findingsForChapterAll.length
        : this.findingsForChapter.length;


      for (let k = 0; k < lll; k++) {
        if (this.chapterEntry.chapterincludes) {
          if (
            this.chapterEntry.chapterV ||
            this.findingsForChapterAll[k].state != "V"
          ) {
            list.push(this.findingsForChapterAll[k]);
          }
        } else {
          if (
            this.chapterEntry.chapterV ||
            this.findingsForChapter[k].state != "V"
          ) {
            list.push(this.findingsForChapter[k]);
          }
        }
      }
      return list;
    },
    */
  },

  mounted() {
    $("#modalDefault").modal("show");

    console.log(
      "Chapter Imge Modal",
      "chapterEntry",
      this.chapterEntry,
      "chapterId",
      this.chapterId,
      "projectNumber",
      this.projectNumber,
      "inspectionEntry",
      this.inspectionEntry,
      "findingsForChapter",
      this.findingsForChapter,
      "findingsForChapterAll",
      this.findingsForChapterAll
    );
    const ref = this;

    $("#modalDefault").on("hidden.bs.modal", function () {
      //ref.saveCanvas();
      ref.$emit("dialogClosed");
    });

    console.log(
      "%c  Results ",
      "background: #222; color: #bada55",
      this.chapterId
    );

    let chapter_uid = "";

    if (this.chapterId.chapter && this.chapterId.chapter.length > 0) {
      chapter_uid += this.chapterId.chapter + "-";
    }

    getChapterSettings(
      this.projectNumber,
      this.inspectionEntry.uid,
      chapter_uid + "" + this.chapterId.level + "-" + this.chapterId.title.text,
      this.projectUid
    ).then((d) => {
      if (d) {
        if (d.data && d.data.imglib) {
          this.$store.state.rp_imglib = [];

          for (let i = 0; i < d.data.projectlib.length; i++) {
            this.$store.state.rp_imglib.push({
              uid: d.data.projectlib[i].uid,
              name: d.data.projectlib[i].name,
              file: d.data.projectlib[i].file,
              width: d.data.projectlib[i].width,
              height: d.data.projectlib[i].height,
              cat: "projectlib",
            });
          }

          for (let i = 0; i < d.data.imglib.length; i++) {
            this.$store.state.rp_imglib.push({
              uid: d.data.imglib[i].uid,
              name: d.data.imglib[i].name,
              file: d.data.imglib[i].file,
              width: d.data.imglib[i].width,
              height: d.data.imglib[i].height,
              cat: "image-lib",
            });
          }
        }

        if (d.data && d.data.chapter) {
          this.chapterEntry = d.data.chapter;
          this.updateFindingsList();
          this.setupCanvas();
        } else {
          this.chapterEntry = {};
          this.updateFindingsList();
        }
      } else {
        this.chapterEntry = {};
      }
    });

    // ------
  },
  methods: {
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
    updateFindingsList: function () {
      let list = [];

      const lll = this.chapterEntry.chapterincludes
        ? this.findingsForChapterAll.length
        : this.findingsForChapter.length;

      for (let k = 0; k < lll; k++) {
        if (this.chapterEntry.chapterincludes) {
          if (
            this.chapterEntry.chapterV ||
            this.findingsForChapterAll[k].state != "V"
          ) {
            list.push(this.findingsForChapterAll[k]);
          }
        } else {
          if (
            this.chapterEntry.chapterV ||
            this.findingsForChapter[k].state != "V"
          ) {
            list.push(this.findingsForChapter[k]);
          }
        }
      }
      this.findingsList = list;
    },
    saveImage() {
      this.saveCanvas();
    },
    stateColorStyle: function (state) {
      return {
        background: this.$store.state.rp_state_color[state],
        "border-color": this.$store.state.rp_state_color[state],
      };
    },
    resetMarker: function (item) {
      console.log(item.id);
      this.updateRemarkPos(item.id, 20, 20, 25, -7);

      if (this.remarkStage.find("#" + item.id).length > 0) {
        this.remarkStage.find("#" + item.id)[0].x(20);
        this.remarkStage.find("#" + item.id)[0].y(20);

        this.remarkStage.find("#" + item.id)[0].children[1].x(25);
        this.remarkStage.find("#" + item.id)[0].children[1].y(-7);
      }
    },
    findImgMeasure: function (file) {
      for (let k = 0; k < this.$store.state.rp_imglib.length; k++) {
        if (this.$store.state.rp_imglib[k].file == file) {
          return this.$store.state.rp_imglib[k];
        }
      }
    },
    setupCanvas: function () {
      const ref = this;

      let cached_results = JSON.parse(
        JSON.stringify(this.chapterEntry.results)
      );

      this.chapterEntry.results = JSON.parse(JSON.stringify(this.findingsList));

      // Für jeden Eintrag in results
      this.chapterEntry.results.forEach((itemA) => {
        // Suche nach einem übereinstimmenden Eintrag in b basierend auf remark_uid
        cached_results.forEach((itemB) => {
          if (itemA.remark_uid === itemB.remark_uid) {
            // Wende die Werte für x und y von itemA auf itemB an
            itemA.x = itemB.x;
            itemA.y = itemB.y;
          }
        });
      });

      this.remarkList = [];

      if (this.chapterEntry && this.chapterEntry.results) {
        for (let k = 0; k < this.chapterEntry.results.length; k++) {
          if (
            ["V", "I", "E", "P", "PP", "PPP"].indexOf(
              this.chapterEntry.results[k].state
            ) != -1
          ) {
            this.chapterEntry.results[k].color =
              this.$store.state.rp_state_color[
                this.chapterEntry.results[k].state
              ];

            if (
              !this.chapterEntry.results[k].x ||
              parseFloat(isNaN(this.chapterEntry.results[k].x))
            ) {
              this.chapterEntry.results[k].x = 20;
            }
            if (
              !this.chapterEntry.results[k].y ||
              parseFloat(isNaN(this.chapterEntry.results[k].y))
            ) {
              this.chapterEntry.results[k].y = 20;
            }
            if (
              !this.chapterEntry.results[k].tx ||
              parseFloat(isNaN(this.chapterEntry.results[k].tx))
            ) {
              this.chapterEntry.results[k].tx = 25;
            }
            if (
              !this.chapterEntry.results[k].ty ||
              parseFloat(isNaN(this.chapterEntry.results[k].ty))
            ) {
              this.chapterEntry.results[k].ty = -7;
            }
            this.remarkList.push(this.chapterEntry.results[k]);
          }
        }
      }

      let chapterImageW = 900;
      let chapterImageH = 500;

      if (this.chapterEntry.chapterfile) {
        let imgEntry = this.findImgMeasure(this.chapterEntry.chapterfile);
        if (imgEntry) {
          chapterImageW = imgEntry.width;
          chapterImageH = imgEntry.height;
        }
      }

      this.remarkStage = new this.$konva.Stage({
        container: "konva-container",
        width: chapterImageW,
        height: chapterImageH,
      });

      var layer = new this.$konva.Layer();
      this.remarkStage.add(layer);

      var layer2 = new this.$konva.Layer();

      for (let k = 0; k < this.remarkList.length; k++) {
        layer2.add(
          this.generateMarker(
            this.remarkList[k].id,
            this.remarkList[k].pos,
            parseFloat(this.remarkList[k].x),
            parseFloat(this.remarkList[k].y),
            parseFloat(this.remarkList[k].tx),
            parseFloat(this.remarkList[k].ty),
            this.remarkList[k].color
          )
        );
      }

      this.remarkStage.add(layer2);

      let mmm = "";

      const toDataURL = (url) =>
        fetch(url)
          .then((response) => response.blob())
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          );

      if (this.chapterEntry.chapterfile) {
        let url = "";
        if (this.chapterEntry.file_cat == "projectlib") {
          url =
            "/projectlib-file?file=" +
            this.chapterEntry.chapterfile +
            "&project_id=" +
            this.projectUid;
        } else {
          url = "/imagelib-file?file=" +
            this.chapterEntry.chapterfile ;
        }



        toDataURL(
          this.$apiBase +
          url
        ).then((dataUrl) => {
          mmm = dataUrl;

          var imageObj = new Image();
          imageObj.src = mmm;
          imageObj.onload = function () {
            var yoda = new ref.$konva.Image({
              x: 0,
              y: 0,
              image: imageObj,
              width: chapterImageW,
              height: chapterImageH,
            });
            // add the shape to the layer
            layer.add(yoda);
          };
        });
      }
    },
    downloadURI: function (uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    saveCanvas: function () {
      var dataURL = this.remarkStage.toDataURL({
        pixelRatio: 1,
      });

      this.saving = true;

      let ref = this;

      let chapter_uid = "";

      if (this.chapterId.chapter && this.chapterId.chapter.length > 0) {
        chapter_uid += this.chapterId.chapter + "-";
      }

      updateChapterMarker(
        this.projectNumber,
        this.inspectionEntry.uid,
        chapter_uid +
          "" +
          this.chapterId.level +
          "-" +
          this.chapterId.title.text,
        this.remarkList,
        dataURL
      ).then((d) => {
        console.log("saved", d);

        setTimeout(function () {
          ref.saving = false;

          $("#modalDefault").modal("hide");
        }, 50);
      });
      //console.log("dataURL", dataURL)

      // this.downloadURI(dataURL, "stage.png");
    },
    generateMarker: function (id, nr, x, y, tx, ty, color) {
      const ref = this;

      var group = new this.$konva.Group({
        draggable: true,
        x: x,
        y: y,
        id: id,
      });
      var node = new this.$konva.Circle({
        stroke: color,
        strokeWidth: 2,
        radius: 10,
        x: 10,
        y: 10,
      });
      group.on("dragend", function () {
        ref.updateRemarkPos(
          group.id(),
          group.x(),
          group.y(),
          simpleText.x(),
          simpleText.y()
        );

        //var dataURL = stage.toDataURL({ pixelRatio: 1 });
        //console.log(dataURL)
      });
      group.add(node);
      var simpleText = new this.$konva.Text({
        text: nr,
        //align: 'center',
        x: tx,
        y: ty,
        width: 40,
        fontSize: 18,
        fontFamily: "Arial",
        fill: color,
      });
      group.add(simpleText);

      group.on("mouseenter", function () {
        ref.remarkStage.container().style.cursor = "pointer";
      });
      group.on("mouseleave", function () {
        ref.remarkStage.container().style.cursor = "default";
      });
      simpleText.on("pointerdblclick", function () {
        //group.draggable = false
        simpleText.startDrag();
        //simpleText.draggable = true
      });
      return group;
    },
    updateRemarkPos: function (id, x, y, tx, ty) {
      for (let k = 0; k < this.remarkList.length; k++) {
        if (this.remarkList[k].id == id) {
          this.remarkList[k].x = x;
          this.remarkList[k].y = y;
          this.remarkList[k].tx = tx;
          this.remarkList[k].ty = ty;
          return;
        }
      }
    },
  },
};
</script>

<style>
.resultContainer {
  width: 100%;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.konvajs-content {
  margin: 0 auto;
  outline: 1px dashed #798bff;
}

.modal-dialog {
  max-width: 1500px;
}
</style>