<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-large">
            <div class="nk-content-inner">
              <div class="nk-content-body">
                <div  v-if="!loading && inspection_count==0">
                  <h3>This project has no content yet.</h3>
                  <p>The report contents can be transferred from Inspect.</p>
                </div>
                <div class="card card-preview" style="margin-bottom: 20px" v-if="!loading && inspection_count> 0">
                  <div>
                    <div class="preview-block">
                      <div class="row" style="margin: 0">
                        <div
                          class="col-sm-4"
                          style="
                            background-color: #f5f6fa;
                            padding-top: 20px;
                            padding-bottom: 20px;
                          "
                        >
                          <div class="form-group">
                            <div class="form-control-wrap">
                              <select
                                class="form-control"
                                id="cbMachineDb"
                                v-model="inspectionEntry"
                                data-ui="lg"
                                data-search="on"
                                @change="inspectionChanged"
                              >
                                <option
                                  :value="entry"
                                  v-for="entry in inspections"
                                  :key="entry.inspection"
                                >
                                  {{ entry.title.serialnumber.text }}
                                  <span v-if="entry.title.siteNumber"
                                    >({{ entry.title.siteNumber.text }})</span
                                  >
                                </option>
                              </select>
                            </div>
                          </div>

                          <h4 v-if="inspectionEntry">
                            {{ inspectionEntry.title.serialnumber.text }}

                            <span v-if="inspectionEntry.title.siteNumber"
                              >({{
                                inspectionEntry.title.siteNumber.text
                              }})</span
                            >
                          </h4>

                          <div
                            style="position: absolute; cursor: pointer"
                            class="chaper-icon"
                            @click="reportSettings(inspectionEntry)"
                          >
                            <span class="material-icons">settings</span>
                          </div>

                          <div
                            v-if="inspectionEntry"
                            style="font-size: 12px; margin-left: 23px"
                          >
                            {{ inspectionEntry.title.reportName.text }}
                          </div>
                          <div
                            v-if="inspectionEntry"
                            style="font-size: 12px; margin-top: 10px"
                          >
                            <span class="badge badge-outline-info">{{
                              inspectionEntry.created_at
                                | moment("D.MM.YYYY - HH:mm")
                            }}</span>

                            <div class="chapterTable">
                              <div
                                v-for="item in findingsChapters"
                                :key="item.uid"
                              >
                                <div class="chapterRow">
                                  <div
                                    class="chapterRow-col2"
                                    @click="selectFindingsChapter(item.id)"
                                  >
                                    <span v-if="item.level == 3"
                                      >&nbsp;&nbsp;</span
                                    >
                                    <span v-if="item.level == 4"
                                      >&nbsp;&nbsp;&nbsp;&nbsp;</span
                                    >
                                    <span v-if="item.level == 5"
                                      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
                                    >
                                    {{ item.title.text }}
                                  </div>
                                  <div class="chapterRow-col1">
                                    <div
                                      class="chaper-icon"
                                      @click="chapterImage(item.id)"
                                      v-if="item.chapterimage"
                                    >
                                      <span class="material-icons">image</span>
                                    </div>
                                  </div>
                                  <div class="chapterRow-col3">
                                    <div
                                      class="chaper-icon"
                                      @click="chapterSettings(item.id)"
                                    >
                                      <span class="material-icons"
                                        >settings</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <button
                                @click="generatePdfReport()"
                                v-if="!pdfLoading"
                                class="btn btn-primary"
                              >
                                Generate PDF
                              </button>
                              <div
                               v-if="pdfLoading"
                                class="spinner-border spinner-grow-sm"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-8" style="padding: 20px">
                          <table class="table table-sm">
                            <thead>
                              <tr>
                                <th>Nr</th>
                                <th></th>
                                <th>Result</th>
                                <th style="width: 250px"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="item in findingsForChapter"
                                :key="item.id"
                              >
                                <td>
                                  {{ item.pos }}
                                </td>
                                <td>
                                  <span
                                    class="badge badge-primary"
                                    v-bind:style="stateColorStyle(item.state)"
                                    >{{ item.state }}</span
                                  >
                                </td>
                                <td>
                                  {{ item.text }}
                                  <!--
                                  <div style="font-size: 10px; color: #999999">
                                    {{ item.componentId }} / {{ item.taskId }}
                                  </div>
-->
                                </td>
                                <td>
                                  <div
                                    v-if="
                                      item.pictures && item.pictures.length > 0
                                    "
                                  >
                                    <div
                                      v-for="itemPic in item.pictures"
                                      :key="itemPic"
                                    >
                                      <a
                                        :href="pictureUrl(item.id, itemPic)"
                                        target="_blank"
                                      >
                                        <img
                                          :src="pictureUrl(item.id, itemPic)"
                                          style="
                                            width: 250px;
                                            padding-bottom: 5px;
                                          "
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->

    <ChapterImageModal
      :findingsForChapter="findingsForChapter"
      :projectNumber="projectNumber"
      :inspectionEntry="inspectionEntry"
      :chapterId="selectedChapterId"
      @dialogClosed="dialogClosed"
      v-if="showChapterImage"
    ></ChapterImageModal>
    <ChapterSettingsModal
      :projectNumber="projectNumber"
      :inspectionEntry="inspectionEntry"
      :chapterId="selectedChapterId"
      :projectUid="projectUid"
      @dialogClosed="dialogClosed"
      v-if="showChapterSettings"
    ></ChapterSettingsModal>
    <InspectionSettings
      :projectNumber="projectNumber"
      :inspectionEntry="inspectionEntry"
      :chapterId="selectedChapterId"
      :projectUid="projectUid"
      @dialogClosed="dialogClosed"
      v-if="showReportSettings"
    ></InspectionSettings>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import {
  getInspections,
  getInspection,
  generateReport,
} from "@/services/reporting.service";
import ChapterSettingsModal from "../../components/modal/ChapterSettings.vue";
import ChapterImageModal from "../../components/modal/ChapterImageModal.vue";
import InspectionSettings from "../../components/modal/InspectionSettings.vue";

export default {
  components: {
    Header,
    ChapterSettingsModal,
    ChapterImageModal,
    InspectionSettings,
  },
  data() {
    return {
      remarkList: [],
      inspectionEntry: null,
      remarkStage: null,
      projectNumber: null,
      findingsChapters: [],
      findingsForChapter: [],
      showChapterSettings: false,
      showChapterImage: false,
      selectedChapterId: null,
      showReportSettings: false,
      projectUid: null,
      pdfLoading: false, 
      loading: false, 
    };
  },
  computed: {
    inspections: function () {
      return this.$store.state.rp_inspections;
    },
    report_role_permissions: function () {
      return this.$store.state.account_role.report == 'write';
    },
    inspection_count: function () {
      return this.$store.state.rp_inspections.length
    }
  },
  mounted() {

    this.projectNumber = this.$route.params.uid;
    this.projectUid = this.$route.params.projectUid;
    this.loading = true
    getInspections(this.projectNumber, this.projectUid).then((d) => {
      this.$store.state.rp_project = d.data.project;
      this.$store.state.rp_inspections = d.data.list;
      this.inspectionEntry = this.$store.state.rp_inspections[0];
      this.inspectionChanged();
      this.$store.state.rp_state_color = d.data.colors;
      this.loading = false

    });



  },
  methods: {
    stateColorStyle: function (state) {
      return {
        background: this.$store.state.rp_state_color[state],
        "border-color": this.$store.state.rp_state_color[state],
      };
    },
    dialogClosed: function () {
      this.showChapterSettings = false;
      this.showChapterImage = false;
      this.showReportSettings = false;
      this._updateChapterSettings();
    },
    pictureUrl: function (id, attachment) {
      return (
        this.$apiBase +
        "/attachment-file?project=" +
        this.projectNumber +
        "&attachment=" +
        attachment
      );
    },
    reportSettings: function (inspection_entry) {
      this.showReportSettings = true;
      console.log("inspection", inspection_entry.inspection);
    },
    chapterImage: function (id) {
      this.selectFindingsChapter(id);
      this.selectedChapterId = id;
      this.showChapterImage = true;
    },
    chapterSettings: function (id) {
      this.selectFindingsChapter(id);
      this.selectedChapterId = id;
      this.showChapterSettings = true;
    },
    generatePdfReport: function () {
      const ref = this;
      this.pdfLoading = true
      generateReport(
        this.inspectionEntry.projectNumber,
        this.inspectionEntry.inspection
      ).then((d) => {
        setTimeout(function () {
            ref.pdfLoading = false

          if (d.data) {
            window.open(
              ref.$apiBase +
                "/pdfViewer/"+d.data+"?project=" +
                ref.inspectionEntry.projectNumber +
                "&file=" +
                d.data
            );
          }

        }, 2000);
      });
    },
    selectFindingsChapter: function (id) {
      this.allFindings(id, true);
    },
    inspectionChanged: function () {
      if (this.inspectionEntry) {
        getInspection(this.projectNumber, this.inspectionEntry.inspection).then(
          (d) => {
            this.$store.state.rp_chapters_settings =
            this.$store.state.rp_chapters_settings = d.data.chapters_settings;
            this.findingsChapters = [];
            this._findings(this.inspectionEntry.findings.list);
            this.$store.state.main_chapters = d.data.main_chapters



            this.selectFindingsChapter(this.findingsChapters[0].id);
          }
        );
      }

    },

    allFindings: function (id, allLevel) {
      let found = false;
      let level = 0;
      this.findingsForChapter = [];
      for (let k = 0; k < this.inspectionEntry.findings.list.length; k++) {
        if (found) {
          if (this.inspectionEntry.findings.list[k].level > level) {
            for (
              let m = 0;
              m < this.inspectionEntry.findings.list[k].list.length;
              m++
            ) {
              this.findingsForChapter.push(
                this.inspectionEntry.findings.list[k].list[m]
              );
            }
          } else {
            break;
          }
        }

        if (this.inspectionEntry.findings.list[k].id == id) {
          console.log(this.inspectionEntry.findings.list[k]);
          level = this.inspectionEntry.findings.list[k].level;
          found = true;

          for (
            let m = 0;
            m < this.inspectionEntry.findings.list[k].list.length;
            m++
          ) {
            this.findingsForChapter.push(
              this.inspectionEntry.findings.list[k].list[m]
            );
          }
        }

        if (!allLevel) {
          break;
        }
      }
    },

    _findings: function (list) {
      if (list && list.length > 0) {
        for (let k = 0; k < list.length; k++) {
          if (list[k].level) {
            //console.log(list[k].level, list[k], list[k].title.text, list[k].id);
            list[k].uid = list[k].id + "-" + Math.random();
            this.findingsChapters.push(list[k]);
          }
        }
      }
      this._updateChapterSettings();
    },
    _updateChapterSettings: function () {
      for (let n = 0; n < this.findingsChapters.length; n++) {
        this.findingsChapters[n].chapterimage = false;
        for (
          let m = 0;
          m < this.$store.state.rp_chapters_settings.length;
          m++
        ) {
          if (
            this.$store.state.rp_chapters_settings[m].chapterId ==
            this.findingsChapters[n].id
          ) {
            this.findingsChapters[n].chapterimage = this.$store.state
              .rp_chapters_settings[m].chapterimage
              ? this.$store.state.rp_chapters_settings[m].chapterimage
              : false;
            break;
          }
        }
      }
    },
    selectInspection: function () {
      this.findingsChapters = [];
      this._findings(this.inspectionEntry.findings.list);
    },
    downloadURI: function (uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    saveCanvas: function () {
      var dataURL = this.remarkStage.toDataURL({
        pixelRatio: 1,
      });

      this.downloadURI(dataURL, "stage.png");
    },
    generateMarker: function (id, nr, posX, posY, textPosX, textPosY, color) {
      const ref = this;

      var group = new this.$konva.Group({
        draggable: true,
        x: posX,
        y: posY,
        id: id,
      });
      var node = new this.$konva.Circle({
        stroke: color,
        strokeWidth: 2,
        radius: 10,
        x: 10,
        y: 10,
      });
      group.on("dragend", function () {
        ref.updateRemarkPos(
          group.id(),
          group.x(),
          group.y(),
          simpleText.x(),
          simpleText.y()
        );

        //var dataURL = stage.toDataURL({ pixelRatio: 1 });
        //console.log(dataURL)
      });
      group.add(node);
      var simpleText = new this.$konva.Text({
        text: nr,
        //align: 'center',
        x: textPosX,
        y: textPosY,
        width: 40,
        fontSize: 18,
        fontFamily: "Arial",
        fill: color,
      });
      group.add(simpleText);

      group.on("mouseenter", function () {
        ref.remarkStage.container().style.cursor = "pointer";
      });
      group.on("mouseleave", function () {
        ref.remarkStage.container().style.cursor = "default";
      });
      simpleText.on("pointerdblclick", function () {
        //group.draggable = false
        simpleText.startDrag();
        //simpleText.draggable = true
      });
      return group;
    },
    updateRemarkPos: function (id, x, y, tX, tY) {
      for (let k = 0; k < this.remarkList.length; k++) {
        if (this.remarkList[k].id == id) {
          this.remarkList[k].x = x;
          this.remarkList[k].y = y;
          this.remarkList[k].tX = tX;
          this.remarkList[k].tY = tY;
          return;
        }
      }
    },
  },
};
</script>

<style scoped >
.chapterTable {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}
.chapterRow {
  cursor: pointer;
  padding: 4px 10px 4px 0;
  position: relative;
  display: table;
  width: 100%;
}

.chapterRow > .chapterRow-col1 {
  display: table-cell;
  vertical-align: top;
  width: 20px;
}

.chapterRow > .chapterRow-col2 {
  display: table-cell;
  vertical-align: top;
  font-size: 13px;
}

.chapterRow > .chapterRow-col3 {
  display: table-cell;
  vertical-align: top;
  width: 20px;
}

.chapterRow > .chapterRow-col2:hover {
  color: #798bff;
}

.chaper-icon > .material-icons {
  font-size: 18px;
  color: #8094ae;
}

.statev {
  border-color: #3bc600;
  background-color: #3bc600;
}

.wide-large {
  max-width: 100% !important;
}
</style>