import {
    io
} from 'socket.io-client';

import { EventBus } from '@/event-bus.js';

class SocketioService {

    socket;
    constructor() {}

    halloWelt(url) {
        console.log(url)
    }
    setupSocketConnection(url) {

        console.log("setupSocketConnection", url)
        this.socket = io(url,{
            transports: [ 'websocket']
          });

        // Auf das "connected"-Ereignis hören
        this.socket.on('connect', () => {
            console.log('Socket.IO verbunden');
            // Fügen Sie hier den Code hinzu, der nach dem Verbindungsaufbau ausgeführt werden soll
        });

        // Auf das "disconnect"-Ereignis hören
        this.socket.on('disconnect', () => {
            console.log('Socket.IO getrennt');
            // Fügen Sie hier den Code hinzu, der nach der Trennung ausgeführt werden soll
        });

        // Auf das "message"-Ereignis hören
        this.socket.on('message', (nachricht) => {
            console.log('Neue Nachricht:', nachricht);
            // Fügen Sie hier den Code hinzu, der bei Empfang einer Nachricht ausgeführt werden soll
        });


        this.socket.on('result_saved', (nachricht) => {
            console.log('result_saved:', nachricht);
            EventBus.$emit('refresh:results', nachricht)
            // Fügen Sie hier den Code hinzu, der bei Empfang einer Nachricht ausgeführt werden soll
        });

    }

    sendRSelectRemark(remark_uid, project_id, inspect_id) {
        this.socket.emit('select_remark', {remark_uid: remark_uid, project_id: project_id, inspect_id: inspect_id});
    }

    sendMessage(nachricht) {
        // Nachricht an den Server senden
        this.socket.emit('message', nachricht);
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}


export default new SocketioService();