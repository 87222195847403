import * as axios from 'axios';

function getContacts() {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/contacts`;
    return axios.get(url, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            return x
        });
}


function createOrUpdateContact(contact) {
    const data = {
        contact: contact
    }

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/create-or-update-contact`;
    return axios.post(url, data, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            return x
        });
}


function deleteContact(contact) {
    const data = {
        contact: contact
    }

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    };

    const url = `/inspect-backend/delete-contact`;
    return axios.post(url, data, headers)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => {
            return x
        });
}   

export {
    getContacts,
    createOrUpdateContact,
    deleteContact
}