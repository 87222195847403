<template>
  <div>
    <div style="padding: 20px; border: 1px solid #666666; margin-bottom: 30px">
      <form @submit.prevent="submitForm">
        <!-- Dropdown für Vertragsauswahl -->
        <label for="contract">Vertrag wählen:</label>
        <select  id="contract">
          <option disabled value="">Bitte wählen</option>
          <option>Anlagenliefervertrag</option>
          <option>Vertrag über die technische und kaufmännische Betriebsführung</option>
     
        </select>
        <div>
          <!-- Mehrfachauswahl für Anlagen -->
          <label>Anlagen wählen:</label>
          <div v-for="facility in facilities" :key="facility">
            <input
              type="checkbox"
              :value="facility"
             
            />
            {{ facility }}
          </div>
        </div>
        <div style="margin-top: 20px">
          <button type="submit">Absenden</button>
        </div>
      </form>
    </div>
    <div class="example-header" style="margin-bottom:20px;">
      <span>Einfache Suche: </span>
      <input
        type="text"
        placeholder="Filter..."
        v-model="filterText"
        @input="onFilterTextBoxChanged"
      />
    </div>


    <ag-grid-vue
      style="width: 100%"
      v-bind:style="{ height: tableH + 'px' }"
      class="ag-theme-alpine"
      :enableCharts="true"
    
      :columnDefs="columnDefs"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :sideBar="sideBar"
      @grid-ready="onGridReady"
      :rowData="rowData"
    >
    </ag-grid-vue>
  </div>
</template>
  
  <script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      columnDefs: null,
      tableH: 700,
      defaultColDef: null,
      gridOptions: null,
      testData: [
        {
          key: 1,
          reportname: "Standort 1",
          anlage: "Nordex N149",
          vertrag: "Anlagenliefervertrag",
          name: "Vertragsgegenstand",
          content:
            "Schlüsselfertige und betriebsbereite Errichtung eines Windparks mit 2 Windenergieanlagen des Typs Nordex N149 mit einer Nabenhöhe von 164 m",
          info: "",
        },
        {
          key: 2,
          reportname: "Standort 1",
          anlage: "Nordex N149",
          vertrag: "Anlagenliefervertrag",
          name: "Vertragsparteien",
          content:
            "Energiepark Beckum-Repowering WP BE 1 GmbH & Co. KG (AG)\nEnergiekontor Infrastruktur VII GmbH & Co. KG (AN)",
          info: "",
        },
        {
          key: 2,
          reportname: "Standort 1",
          anlage: "Nordex N149",
          vertrag: "Anlagenliefervertrag",
          name: "Vertragspreis",
          content:
            "Zusage eines Gesamtinvestitionsbetrages in Höhe von 22.561.00,00€ ",
          info: "",
        },
        {
          key: 2,
          reportname: "Standort 1",
          anlage: "Nordex N149",
          vertrag: "Anlagenliefervertrag",
          name: "Gewährleistung",
          content: "3 Jahre nach Abnahme der jeweiligen Leistungen",
          info: "Wir empfehlen eine juristische Prüfung.",
        },
        {
          key: 1,
          reportname: "Standort 2",
          anlage: "Nordex N117",
          vertrag: "Anlagenliefervertrag",
          name: "Vertragsgegenstand",
          content:
            "Lieferung, Montage und IBN von drei (3) WEA des Typs Nordex N117/3000kW mit 141m Nabenhöhe inkl. Fundament.",
          info: "Marktüblicher Umfang eines WEA-Kaufvertrages",
        },
        {
          key: 2,
          reportname: "Standort 2",
          anlage: "Nordex N117",
          vertrag: "Anlagenliefervertrag",
          name: "Lieferumfang",
          content:
            "Lieferung, Montage und IBN von drei (3) WEA des Typs Nordex N117/3000kW mit 141m Nabenhöhe inkl. Fundament.",
          info: "Marktüblicher Lieferumfang",
        },
        {
          key: 2,
          reportname: "Standort 2",
          anlage: "Nordex N117",
          vertrag: "Anlagenliefervertrag",
          name: "Vertragspreis",
          content:
            "Laut Werkliefervertrag:\nNordex N117/3000kW: 3.757.000,00€/WEA (Gesamt: 11.271.00,00€)",
          info: "Der Preis kann als marktüblich angesehen werden.",
        },
      ],
      selectedContract: "",
      facilities: ["Nordex N149", "Nordex N117"],
      selectedFacilities: [],
    };
  },
  mounted() {},
  methods: {
    onGridReady() {
      this.rowData = [];
      //console.log("getDisplayedRowCount",  this.gridOptions.api.getDisplayedRowCount())
    },
    submitForm() {
      // Logik zur Verarbeitung der Formulardaten
      //   console.log("Gewählter Vertrag:", this.selectedContract);
      //   console.log("Ausgewählte Anlagen:", this.selectedFacilities);
      alert("Nur ein Test");
    },
    onFilterTextBoxChanged() {
      this.gridOptions.api.setQuickFilter(this.filterText);
    }
  },
  beforeMount() {
    this.gridOptions = {
      // EVENTS
      // Add event handlers
      onCellClicked: (event) =>
        function (x) {
          console.log("cellClicked", event, x);
        },
      //onColumnResized: event => console.log('A column was resized'),
      onGridReady: () => console.log("The grid is now ready"),
    };

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,

      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,

      // Optionale feste Zeilenhöhe
      rowHeight: 50,
      // Oder automatische Größenanpassung der Zeilen aktivieren
      autoHeight: true,

      editable: true,
      filter: "agTextColumnFilter",
      // floatingFilter: true,
      /*
                // set the default column width
                //width: 150,
                // make every column editable
                // make every column use 'text' filter by default
                filter: 'agTextColumnFilter',
                // enable floating filters by default
                floatingFilter: true,
                // make columns resizable
                resizable: true,
                sideBar: null,
                // autoHeight: true,
              */
    };
    this.columnDefs = [
      {
        headerName: "Bericht",
        field: "reportname",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Anlagentyp",
        field: "anlage",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Vertragsart",
        field: "vertrag",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Beschreibung",
        field: "name",
        sortable: true,
        filter: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: "Inhalt",
        field: "content",
        sortable: true,
        filter: true,
        cellStyle: { "white-space": "normal" },
      },
      {
        headerName: "Bemerkung",
        field: "info",
        sortable: true,
        filter: true,
        cellStyle: { "white-space": "normal" },
      },
    ];

    this.sideBar = "columns";

    this.rowData = this.testData;
  },
};
</script> 
  
  <style>
</style>