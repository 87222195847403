<template>
  <div>
    <!--
    <div style="padding: 0 0 20px 0;text-align:right;" v-if="report_role_permissions">
      <a :href="'/report/'+project.uid+'/'+project.projectId" target="_blank" class="btn btn-primary">Open Reports</a>
    </div>
-->
    <table class="table">
      <thead>
        <tr>
          <th scope="col" class="hide-mobile">Name</th>
          <th scope="col"></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in insp_list" :key="item.uid">
          <td :style="{ 'border-left': '4px solid ' + item.color }">{{ i + 1 }}</td>
          <td >
            <b>{{ item.title.de }}</b><br />
            {{ item.scope.de }}<br />
            <b>{{ item.machineName }}</b> - {{ item.project_cat }}
          </td>
          <td>
            <!--
            <div
              class="btn btn-outline-primary"
              style="border: none; padding: 3px 7px"
              @click="loadDetails(item.uid)"
            >
              Details
            </div>
-->
            <a :href="'/report-v3/de/'+item.project_id+'/'+item.uid" target="_blank"  class="btn btn-dim btn-outline-primary">Bericht</a>
            <a :href="'/report-v3/en/'+item.project_id+'/'+item.uid" target="_blank" style="margin-left:10px;" class="btn btn-dim btn-outline-primary">Report</a>

        
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal Inspection Code -->
    <div class="modal fade" id="modalDefaultInspection">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <a href="#" class="close" data-dismiss="modal" aria-label="Close">
            <em class="icon ni ni-cross"></em>
          </a>
          <div class="modal-header">
            <h5 class="modal-title">Inspection</h5>
          </div>
          <div class="modal-body">
            <form
              action="#"
              class="form-validate is-alter"
              novalidate="novalidate"
            >
              <div class="form-group" v-if="inspection_detail">
                
                <div class="row gy-4">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="default-01">Name</label>
                      <div class="form-control-wrap">
                        <div v-if="inspection_detail.title">
                          {{ inspection_detail.title.de }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="default-01">Name</label>
                      <div class="form-control-wrap">
                        <div v-if="inspection_detail.name">
                          {{ inspection_detail.name.en }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gy-4">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label" for="default-01"
                        >Project type</label
                      >
                      <div class="form-control-wrap">
                        <span v-if="inspection_detail.projectType">{{
                          inspection_detail.projectType.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gy-4" v-if="inspection_detail.machine">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="default-01"></label>
                      <div class="form-control-wrap">
                        <span v-if="inspection_detail.machine">{{
                          inspection_detail.machine.name
                        }}</span>
                        <span v-if="inspection_detail.machine.siteNumber"
                          >({{ inspection_detail.machine.siteNumber }})</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="default-01">Machine</label>
                      <div class="form-control-wrap">
                        <div v-if="inspection_detail.machine">
                          {{ inspection_detail.machine.machineType }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row gy-4"
                  v-if="inspection_detail.inspectionDetails"
                >
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="form-label" for="default-01"
                        >Inspection Details</label
                      >
                      <div class="form-control-wrap">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col" class="hide-mobile">Date</th>
                              <th scope="col">Inspector</th>
                              <th>Weather</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                insp_entry, i
                              ) in inspection_detail.inspectionDetails"
                              :key="i"
                            >
                              <td>
                                {{ insp_entry.date | moment("D.M.YYYY") }}
                              </td>
                              <td>
                                {{ insp_entry.inspector }}
                              </td>

                              <td>
                                {{ insp_entry.weather }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listInspections, getInspection } from "@/services/project.service";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      member_username: "",
      infoNotFound: false,
      insp_list: [],
      inspection_detail: null,
    };
  },
  computed: {
    member_list: function () {
      return this.$store.state.project_member;
    },
    user_uid: function () {
      return this.$store.state.user_uid;
    },
    project: function () {
      return this.$store.state.project;
    },
    canSave: function () {
      return (
        this.project &&
        this.project.editor_list &&
        this.project.editor_list.indexOf(this.user_uid) != -1
      );
    },
    report_role_permissions: function () {
      return this.$store.state.account_role.report == 'write';
    },
  },
  mounted() {
    listInspections(this.$route.params.uid).then((result) => {
      console.log("result", result.data.inspections);

      this.insp_list = result.data.inspections;

      this.$store.state.project_inspections = result.data.inspections;
    });
  },
  methods: {
    loadDetails: function (uid) {
      this.showLoader();

      getInspection(this.$route.params.uid, uid).then((data) => {
        if (data.data.insp_entry) {
          this.inspection_detail = data.data.insp_entry;

          $("#modalDefaultInspection").modal("show");
        }
        this.hideLoader();
      });
    },
    openResults: function (uid) {
      console.log(uid)

    },

    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1000px;
  }
}
</style>
