<template>
  <div>
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap">
          <Header v-show="!loading_page" />
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container wide-xl">
              <div class="nk-content-inner">
                <Navigation v-show="!loading_page" />
                <!--
                <div class="nk-content-body" v-show="loading_page">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                -->
                <div class="nk-content-body" v-show="!loading_page">
                  <div class="nk-content-wrap">
                    <div class="nk-block-head nk-block-head-lg">
                      <div class="nk-block-head-sub">
                        <span>8.2 Inspect</span>
                      </div>
                      <div class="nk-block-head-content">
                        <h2 class="nk-block-title fw-normal">
                          Booking History
                        </h2>
                      </div>
                    </div>

                    <div class="nk-block">
                      <div class="card card-bordered card-stretch">
                        <div class="card-inner-group">
                          <div class="card-inner">
                            <div class="card-title-group">
                              {{ bookingList.length }} Entries
                              <div class="card-tools mr-n1">
                                <ul class="btn-toolbar">
                                  <li>
                                    <div class="form-group">
                                      <div class="form-control-wrap">
                                        <div
                                          class="
                                            input-daterange
                                            date-picker-range
                                            input-group
                                          "
                                        >
                                          <input
                                            class="datepicker1 form-control"
                                            data-date-format="yyyy-mm"
                                            id="booking-date"
                                          />

                                          <div
                                            class="input-group-addon "
                                            style="cursor: pointer"
                                            @click="actionGetBookings()"
                                          >
                                            REFRESH
                                          </div>
                                          <!-- <input class="datepicker2 form-control" data-date-format="mm/yyyy" /> -->
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <!-- li -->
                                  <li class="btn-toolbar-sep"></li>
                                  <!-- li -->

                                  <li>
                                    <div class="dropdown">
                                      <a
                                        href="#"
                                        class="
                                          btn btn-trigger btn-icon
                                          dropdown-toggle
                                        "
                                        data-toggle="dropdown"
                                      >
                                        <em class="icon ni ni-setting"></em>
                                      </a>
                                      <div
                                        class="
                                          dropdown-menu
                                          dropdown-menu-right
                                          dropdown-menu-xs
                                        "
                                      >
                                        <ul class="link-check">
                                          <li>
                                            <span
                                              style="cursor: pointer"
                                              @click="exportCSV()"
                                              >Export CSV</span
                                            >
                                          </li>
                                          <li v-if="bookings_all_role_permissions">
                                            <span
                                              style="cursor: pointer"
                                              @click="exportCSV(true)"
                                              >Export all</span
                                            >
                                          </li>
                                          <!-- <li class="active">
                                            <a href="#">10</a>
                                          </li>
                                          -->
                                        </ul>
                                      </div>
                                    </div>
                                  </li>

                                  <!-- li -->
                                </ul>
                                <!-- .btn-toolbar -->
                              </div>
                              <!-- card-tools -->
                              <div
                                class="card-search search-wrap"
                                data-search="search"
                              >
                                <div class="search-content">
                                  <a
                                    href="#"
                                    class="
                                      search-back
                                      btn btn-icon
                                      toggle-search
                                    "
                                    data-target="search"
                                    ><em class="icon ni ni-arrow-left"></em
                                  ></a>
                                  <input
                                    type="text"
                                    class="
                                      form-control form-control-sm
                                      border-transparent
                                      form-focus-none
                                    "
                                    placeholder="Quick search by order id"
                                  />
                                  <button class="search-submit btn btn-icon">
                                    <em class="icon ni ni-search"></em>
                                  </button>
                                </div>
                              </div>
                              <!-- card-search -->
                            </div>
                            <!-- .card-title-group -->
                          </div>
                          <!-- .card-inner -->
                          <div class="card-inner p-0">
                            <table class="table table-tranx">
                              <thead>
                                <tr class="tb-tnx-head">
                                  <!--
                                  <th class="tb-tnx-id" style="width: 50px">
                                    <span class="">#</span>
                                  </th>
                                  -->
                                  <th style="width: 100px;cursor:pointer;" @click="sortBy('created_at')">
                                    <span class="tb-tnx-total">Date</span>
                                  </th>
                                  <th style="cursor:pointer;" @click="sortBy('project')">Project</th>
                                  <th style="cursor:pointer;" @click="sortBy('machine')">Machine</th>
                                  <th style="cursor:pointer;" @click="sortBy('type')">Type</th>
                                  <th style="cursor:pointer;" @click="sortBy('username')">User</th>
                                  <th style="cursor:pointer;" @click="sortBy('inspector')">Inspector</th>
                                </tr>
                                <!-- tb-tnx-item -->
                              </thead>
                              <tbody> 
                                <tr
                                  class="tb-tnx-item"
                                  v-for="(item) in bookingList"
                                  :key="item._id" 
                                >
                                <!--                                  <td class="tb-tnx-id">
                                    <a href="#"
                                      ><span>{{ index + 1 }}</span></a
                                    >
                                  </td>
                                  -->

                                  <td class="tb-tnx-info">
                                    <span class="date"
                                      >{{ item.created_at | moment("DD.MM.YY")
                                      }}<br />{{
                                        item.created_at | moment("HH:mm")
                                      }}</span
                                    >
                                  </td>
                                  <td class="tb-tnx-info">
                                    {{ item.projectNumber }}<br />
                                    {{ item.site }}
                                  </td>

                                  <td class="tb-tnx-info">
                                    {{ item.machine }}
                                    ({{ item.siteNumber }})
                                  </td>
                                  <td class="tb-tnx-info">{{ item.type }}<br />
                                  {{ item.projectCat }}</td>

                                  <td class="tb-tnx-info">{{ item.username }}</td>
                                  <td class="tb-tnx-info">{{ item.inspector }}</td>
                                </tr>

                                <!-- tb-tnx-item -->
                              </tbody>
                            </table>
                          </div>
                          <!-- .card-inner -->

                          <!-- .card-inner -->
                        </div>
                        <!-- .card-inner-group -->
                      </div>
                      <!-- .card -->
                    </div>

                    <!-- .nk-block-head -->

                    <!--
                    https://github.com/revolist/revogrid/blob/master/src/components/revo-grid/readme.md
                -->

                    <!-- .nk-block -->
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
//import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";
import moment from "moment";
import { getBookings } from "@/services/account.service";

//import { EventBus } from '@/event-bus.js';

export default {
  name: "Home",
  data() {
    return {
      fullLoader: null,
      projectEntry: {},
      mapSort: {},
      searchkey: "",
      db_result: [],
      projectLoading: false,
      iec_daaks_value: "",
      settingsSaved: false,
      bookingDate: null,
      bookingList: [],
    };
  },
  components: {
    Header,
    Navigation,
    Footer
  },

  computed: {
    account_role_permissions: function () {
      return this.$store.state.account_role.permissions == "write";
    },
    bookings_role_permissions: function () {
      return this.$store.state.account_role.bookings == "write";
    },
    bookings_all_role_permissions: function () {
      return this.$store.state.account_role.bookings_all == "write";
    },
    companyName: function () {
      return this.$store.state.company;
    },

    loginName: function () {
      return this.$store.state.name;
    },

    loading_page: function () {
      return (
        this.$store.state.loading_page &&
        this.$store.state.loading_page == "setup"
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (!this.bookings_role_permissions) {
      this.$store.dispatch("api_error", {});
    } 

    $("#booking-date").val(moment().format("yyyy-MM"));

    $(".datepicker1").datepicker({
      format: "yyyy-mm",
    viewMode: "months", 
    minViewMode: "months"
    });

    this.actionGetBookings();
  },
  created() {},
  watch: {},
  methods: {
    sortByKey(key, idx) {
      if (idx == -1) {
        return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
      } else {
        return (a, b) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
      }
    },
    sortBy: function(val) {
      console.log("sort", val)

      if (!this.mapSort[val]) {
        this.mapSort[val] = 1;
      } else {
        this.mapSort[val] = this.mapSort[val] * -1;
      }

      this.bookingList = this.bookingList.sort(
        this.sortByKey(val, this.mapSort[val])
      );

    },
    exportCSV: function (all = false) {
      if ($("#booking-date").val() && $("#booking-date").val().length > 2) {
        this.showLoader();
        getBookings($("#booking-date").val(), true, all).then((resp) => {
          console.log(resp);
          this.bookingList = resp.data.list;

          if (resp.data.csv) {
            var csvData = new Blob(["\uFEFF" + resp.data.csv], {
              type: "text/csv;charset=windows-1252",
            });
            var csvURL = null;
            if (navigator.msSaveBlob) {
              csvURL = navigator.msSaveBlob(csvData, "download.csv");
            } else {
              csvURL = window.URL.createObjectURL(csvData);
            }
            var tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute(
              "download",
              $("#booking-date").val() + "_bookings.csv"
            );
            tempLink.click();
          }

          this.hideLoder();
        });
      }
    },
    actionGetBookings: function () {
      if ($("#booking-date").val() && $("#booking-date").val().length > 2) {
        this.showLoader();
        getBookings($("#booking-date").val(), false).then((d) => {
          console.log(d);

          this.bookingList = d.data.list;

          this.hideLoder();
        });
      }
    },
    hideLoder: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },

    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style scoped>
.revo-grid {
  height: 50%;
}
</style>
