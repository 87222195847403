<template>
  <div>
    <div class="nk-app-root">
      <!-- main @s -->
      <div class="nk-main">
        <!-- wrap @s -->
        <div class="nk-wrap">
          <Header v-show="!loading_page" />
          <!-- main header @e -->
          <!-- content @s -->
          <div class="nk-content">
            <div class="container wide-xl">
              <div class="nk-content-inner">
                <Navigation v-show="!loading_page" />
                <!--
                <div class="nk-content-body" v-show="loading_page">
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                -->
                <div class="nk-content-body" v-show="!loading_page">
                  <div class="nk-content-wrap">
                    <div class="nk-block-head nk-block-head-lg">
                      <div class="nk-block-head-sub">
                        <span>8.2 Inspect</span>
                      </div>
                      <div class="nk-block-head-content">
                        <h2 class="nk-block-title fw-normal">Permissions</h2>
                      </div>
                    </div>

                    <ul class="nav nav-tabs nav-tabs-s2">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#tabItem9"
                          >Members</a
                        >
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#tabItem11"
                          >Settings</a
                        >
                      </li>
                    </ul>

                    <div class="tab-content">
                      <div class="tab-pane active" id="tabItem9">
                        <PermissionsMember />
                      </div>
                      <div class="tab-pane" id="tabItem11">
                        <div class="form-control-wrap" style="margin-top: 50px">
                          <div class="input-group">
                            <input
                              v-model="iec_daaks_value"
                              type="text"
                              class="form-control"
                              placeholder="Daaks (de)"
                            />
                        
                          </div>
                        </div>
                        <div class="form-control-wrap" style="margin-top: 20px">
                          <div class="input-group">
                            <input
                              v-model="iec_daaks_value_en"
                              type="text"
                              class="form-control"
                              placeholder="Daaks (en)"
                            /> 
                           
                          </div>
                        </div>
                        <div  style="text-align:right;">
                              <button
                                @click="upateSettings()"
                                class="btn btn-outline-primary btn-dim"
                                style="margin-top: 20px"
                              >
                                Update Daaks
                              </button>
                            </div>
                        <div
                          class="alert alert-warning alert-icon"
                          style="margin-top: 30px"
                          v-if="settingsSaved"
                        >
                          <em class="icon ni ni-alert-circle"></em> Saved
                        </div>
                      </div>
                    </div>

                    <!-- .nk-block-head -->

                    <!--
                    https://github.com/revolist/revogrid/blob/master/src/components/revo-grid/readme.md
                -->

                    <!-- .nk-block -->
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <!-- content @e -->
        </div>
        <!-- wrap @e -->
      </div>
      <!-- main @e -->
    </div>
  </div>
</template>

<script>
//import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

import PermissionsMember from "../../components/PermissionsMember.vue";

import { updateSettings, getSettings } from "@/services/account.service";

//import { EventBus } from '@/event-bus.js';

export default {
  name: "Home",
  data() {
    return {
      fullLoader: null,
      projectEntry: {},
      mapSort: {},
      searchkey: "",
      db_result: [],
      projectLoading: false,
      iec_daaks_value: "",
      iec_daaks_value_en: "", 
      settingsSaved: false,
    };
  },
  components: {
    Header,
    Navigation,
    Footer,
    PermissionsMember,
    //VGrid
  },

  computed: {
    account_role_permissions: function () {
      return this.$store.state.account_role.permissions == "write";
    },
    bookings_role_permissions: function () {
      return this.$store.state.account_role.bookings == "write";
    },
    companyName: function () {
      return this.$store.state.company;
    },



    loginName: function () {
      return this.$store.state.name;
    },

    loading_page: function () {
      return (
        this.$store.state.loading_page &&
        this.$store.state.loading_page == "setup"
      );
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    if (!this.account_role_permissions) {
      this.$store.dispatch("api_error", {});
    } 

    getSettings("iec_daaks").then((d) => {
      if (d.data) {

        if (d.data.de) {
          this.iec_daaks_value = d.data.de;
          this.iec_daaks_value_en = d.data.en; 
        }
        else {
          this.iec_daaks_value = d.data;
        }
        
      }
    });
  },
  created() {},
  watch: {},
  methods: {
    upateSettings: function () {
      this.settingsSaved = false;

      updateSettings("iec_daaks", { en: this.iec_daaks_value_en, de: this.iec_daaks_value } ).then((d) => {
        this.settingsSaved = true;
        console.log(d);
      });


    },
    hideLoder: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },

    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style scoped>
.revo-grid {
  height: 50%;
}
</style>
