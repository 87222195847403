<template>
  <div class="modal fade" tabindex="-1" id="modalDefault">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <em class="icon ni ni-cross"></em>
        </a>
        <div class="modal-header">
          <h5 class="modal-title">Report Settings</h5>
        </div>
        <div class="modal-body">
          <form action="#" class="form-validate is-alter" novalidate="novalidate">
            <ul class="nav nav-tabs nav-tabs-s2">
              <li class="nav-item">
                <a class="nav-link " data-toggle="tab" href="#tabItem1">General</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#tabItemP2">Chapters</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabItemP3">Style</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabItemP4">States</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane " id="tabItem1" style="padding-top: 20px">
                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-6">
                      <div style="margin-bottom: 20px">
                        <div class="form-group">
                          <label class="form-label" for="default-01">
                            Report style
                          </label>
                          <select v-model="reportEntry.reportLayout" style="max-width: 60%" class="form-control">
                            <option value="1">Standard</option>
                            <option value="2">Layout 2</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="form-label" for="default-01">Report picture</label>
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1"
                              v-model="reportEntry.reportimage" />
                            <label class="custom-control-label" for="customCheck1">Show Report image</label>
                          </div>
                          <div v-if="reportEntry.reportimage" style="margin-top: 10px">
                            <select class="form-control" style="max-width: 60%" v-model="reportEntry.reportimagefile">
                              <option :value="item.file" v-for="item in imglib_list" :key="item.uid">
                                {{ item.name }}
                              </option>
                            </select>
                            <div class="form-group" v-if="reportEntry.reportimage" style="margin-top: 10px">
                              <label class="form-label" for="default-01">Report image width</label>
                              <div class="form-control-wrap">
                                <input v-model="reportEntry.reportimagewidth" type="text" style="max-width: 60%"
                                  class="form-control" id="default-01" placeholder="Image width" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="form-label">Construction progress control</label>
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheckCpc10"
                              v-model="reportEntry.reportTargetState" />
                            <label class="custom-control-label" for="customCheckCpc10">Hide TARGET State</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheckCpc11"
                              v-model="reportEntry.reportLocationData" />
                            <label class="custom-control-label" for="customCheckCpc11">Show Location data</label>
                          </div>
                        </div>
                      </div>
                      <!--
                      <div class="form-group" style="margin-top: 10px">
                        <label class="form-label" for="default-decimal-places">Decimal places</label>
                        <div class="form-control-wrap">
                          <input v-model="reportEntry.reportiDecimalPlaces" type="text" style="max-width: 10%"
                            class="form-control" id="default-decimal-places" />
                        </div>
                      </div>
                      -->
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row gy-4">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck5"
                              v-model="reportEntry.twoImagesInRow" />
                            <label class="custom-control-label" for="customCheck5">Show 2 images in a row</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck5c"
                              v-model="reportEntry.oneImageInRow" />
                            <label class="custom-control-label" for="customCheck5c">Show one image in a row</label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group" v-if="reportEntry.oneImageInRow" style="margin-top: 10px">

                        <div class="form-control-wrap">
                          <input v-model="reportEntry.oneImageMaxWidth" type="text" style="max-width: 60%"
                            class="form-control" id="default-01" placeholder="Image width" />
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck5b"
                              v-model="reportEntry.imagesBelowChapter" />
                            <label class="custom-control-label" for="customCheck5b">Images below chapter</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck19"
                              v-model="reportEntry.reportColouredState" />
                            <label class="custom-control-label" for="customCheck19">Coloured state (Text)</label>
                          </div>
                        </div>
                      </div>

                      <label class="form-label" for="default-01">Offshore</label>
                      <div class="form-group">
                        
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck30"
                              v-model="reportEntry.reportBarState" />
                            <label class="custom-control-label" for="customCheck30">State as coloured bars</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck31"
                              v-model="reportEntry.reportConditionGrade" />
                            <label class="custom-control-label" for="customCheck31">Condition grade</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck1zz"
                              v-model="reportEntry.reportResultId" />
                            <label class="custom-control-label" for="customCheck1zz">Show Result ID</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck10"
                              v-model="reportEntry.reportResponsibility" />
                            <label class="custom-control-label" for="customCheck10">Show Responsibility</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck15"
                              v-model="reportEntry.reportDisableImageLink" />
                            <label class="custom-control-label" for="customCheck15">Deactivate linking of images</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck17"
                              v-model="reportEntry.stackOfParagraphs" />
                            <label class="custom-control-label" for="customCheck17">Stack of paragraphs<br /><span class="subinfo">General, Conclusion</span></label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="form-control-wrap">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customCheck18"
                              v-model="reportEntry.outputAllChapters" />
                            <label class="custom-control-label" for="customCheck18">Output all chapters</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane active" id="tabItemP2" style="padding-top: 20px">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col">New page</th>
                      <th scope="col">Hide</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr v-for="item in main_chapters" :key="item.chapter">
                      <td>{{ item.chapter }}</td>
                      <td>{{ item.title }}</td>
                      <td>
                        <div v-if="item.chapter != 'A'">
                          <input type="checkbox" :id="item.chapter" :value="item.chapter" name="pagenew"
                            v-model="reportEntry.checkedNewPage" />
                        </div>
                      </td>
                      <td>
                        <div v-if="item.chapter == 'G'">
                          <input type="checkbox" :id="item.chapter" :value="item.chapter" name="pagenew"
                            v-model="reportEntry.checkedHide" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>



              <div class="tab-pane" id="tabItemP3" style="padding-top: 20px">

                <div class="row gy-4">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">H1</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeH1" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeH1" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">H2</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeH2" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeH2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">H2 (Layout 2)</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeH2_2" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeH2_2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row gy-4">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">H3</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeH3" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeH3" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Toc Level 1</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeToc1" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeToc1" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Toc Level 2</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeToc2" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeToc2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gy-4">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Cover Sheet (Layout 2)</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeText" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeText" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Table / Results</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeTable" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeTable" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Text</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeCopy" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeCopy" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row gy-4">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Footer</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeFooter" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeFooter" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Conclusion</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeConclusion" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeConclusion" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Disclaimer</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.fontSizeDisclaimer" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="fontSizeDisclaimer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row gy-4">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Color H1</label>
                          <div class="form-control-wrap">
                            <input v-model="reportEntry.headlineColor1" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="headlineColor1" />
                            <input type="color" class="form-control"
                              style="cursor:pointer;height:30px;padding: 0px; border:none;max-width: 30%"
                              v-model="reportEntry.headlineColor1">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <div class="form-control-wrap">
                        <div class="form-group">
                          <label class="form-label" for="default-01">Color H2</label>
                          <div class="form-control-wrap" style="position: relative;">

                            <input v-model="reportEntry.headlineColor2" type="text" style="max-width: 30%"
                              class="form-control" id="default-01" placeholder="headlineColor2" />
                            <input type="color" class="form-control"
                              style="cursor:pointer;height:30px;padding: 0px; border:none;max-width: 30%"
                              v-model="reportEntry.headlineColor2">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group"></div>
                  </div>
                </div>

              </div>


              <div class="tab-pane" id="tabItemP4" style="padding-top: 20px">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Column width
                      </td>
                      <td>
                        <input type="text" maxlength="3" style="width: 200px" class="form-control"  v-model="reportEntry.stateColWidth"  />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="icon-bg"> <font-awesome-icon style="font-size:20px;color:#3EC997"
                            icon="fa-solid fa-check" /></div>
                      </td>
                      <td>
                        <input type="text"  style="width: 200px" class="form-control"  v-model="reportEntry.stateV"  />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="icon-bg"> 
                        <font-awesome-icon style="font-size:20px;color:#009dff" icon="fa-solid fa-circle-info" /></div>
                      </td>
                      <td>
                        <input type="text" style="width: 200px" class="form-control"  v-model="reportEntry.stateI"  />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="icon-bg"> 
                        <font-awesome-icon style="font-size:20px;color:#0000ff" icon="fa-solid fa-triangle-exclamation" /></div>
                      </td>
                      <td>
                        <input type="text" style="width: 200px" class="form-control"  v-model="reportEntry.stateE"  />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="icon-bg"> 
                        <font-awesome-icon style="font-size:20px;color:#ffff00" icon="fa-solid fa-triangle-exclamation" /></div>
                      </td>
                      <td>
                        <input type="text" style="width: 200px" class="form-control"  v-model="reportEntry.stateP"  />
                      </td>
                    </tr>
                    <tr>  
                      <td>
                        <div class="icon-bg"> 
                        <font-awesome-icon style="font-size:20px;color:#ff8000" icon="fa-solid fa-triangle-exclamation" /></div>
                      </td>
                      <td>
                        <input type="text" style="width: 200px" class="form-control"  v-model="reportEntry.statePP"  />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="icon-bg"> 
                        <font-awesome-icon style="font-size:20px;color:#ff0000" icon="fa-solid fa-triangle-exclamation" /></div>
                      </td>
                      <td>
                        <input type="text" style="width: 200px" class="form-control"  v-model="reportEntry.statePPP"  />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="icon-bg"> 
                        X</div>
                      </td>
                      <td>
                        <input type="text" style="width: 200px" class="form-control"  v-model="reportEntry.stateNT"  />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group" style="margin-top: 30px">
              <button type="button" style="text-align: center; width: 100%" class="btn-lg btn-primary"
                @click="updateSettings">
                Save
              </button>
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light"></div>
      </div>
    </div>
  </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";

import {
  getReportSettings,
  saveReportSettings,
} from "@/services/reporting.service";

export default {
  props: ["chapterId", "projectNumber", "inspectionEntry"],
  data() {
    return {
      reportEntry: {},
      saved: false, 
      selectedColor: '#ff0000' // Standardfarbe
    };
  },

  computed: {
    imglib_list: function () {
      return this.$store.state.rp_imglib_project;
    },
    main_chapters: function () {
      return this.$store.state.main_chapters;
    },
  },
  beforeDestroy() {
    EventBus.$off("keydown");
  },
  mounted() {

    
    EventBus.$off("keydown");
    EventBus.$on("keydown", this.keyDown);

    $("#modalDefault").modal("show");

    const ref = this;

    $("#modalDefault").on("hidden.bs.modal", function () {
      ref.$emit("dialogClosed", { saved: ref.saved, cat: 'inspection' });
    });

    this.reportEntry = {
      reportimage: false,
      reportimagefile: "",
      checkedNewPage: [],
      checkedHide: [],
    };

    getReportSettings(
      this.projectNumber,
      this.inspectionEntry.uid,
      this.$store.state.rp_project.uid
    ).then((d) => {
      if (d) {
        if (d.data.report) {
          this.reportEntry = d.data.report;
        }

        this.$store.state.rp_imglib_project = d.data.imglib;
      }
    });

    // ------
  },
  methods: {

    keyDown: function (cat) {
      if (cat == "save") {
        this.updateSettings()
      }
 
    },

    updateSettings: async function () {
      await saveReportSettings(
        this.projectNumber,
        this.inspectionEntry.uid,
        this.reportEntry
      ).then(() => {
        this.saved = true;
        //this.$store.state.rp_chapters_settings = d.data.chapters_settings;
        $("#modalDefault").modal("hide");
      });
    },
  },
};
</script>

<style>
.icon-bg {
  padding: 10px 0 5px 0;
  text-align: center;
  background-color: #e5ecf5;
}

.subinfo {
  color: #8094ae; 
}
</style>