<template>
  <div>
    <div style="margin-bottom:30px;">
      Collaborators can open the project in Inspect.
    </div>

    <table class="table" v-if="member_list.length > 0">
      <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col" class="hide-mobile">Name</th>
          <th scope="col">Provider</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in member_list" :key="user.uid">
          <td>{{ user.username }}</td>
          <td class="hide-mobile">
            {{ user.name }}
          </td>
          <td>
            <span v-if="user.login_system == 'pdb'" class="badge bg-outline-primary" >PDB</span>
            <span v-if="user.login_system == 'pdb2'" class="badge bg-outline-primary">PDB 2.0</span>
            <span v-if="user.login_system == 'inspect'" class="badge bg-outline-primary">Inspect</span>
            <span v-if="user.login_system == 'vertec'" class="badge bg-outline-primary">Vertec</span>
          </td>
          <td>
            <div v-if="canSave" class="btn btn-outline-primary"
              style="border: none; padding: 3px 7px" @click="removeMember(user)">
              Remove
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form-control-wrap" style="margin-top: 50px" v-if="canSave">
      <div class="input-group">
        <input v-model="member_username" type="text" class="form-control" placeholder="Username" />
        <select class="form-control" id="default-06" v-model="member_provider">
          <option value="pdb">PDB</option>
          <option value="vertec">Vertec</option>
          <option value="pdb2">PDB2.0</option>
          <option value="inspect">Inspect Panel</option>
        </select>
        <div class="input-group-append">
          <button @click="addMember()" class="btn btn-outline-primary btn-dim">
            Add member
          </button>
        </div>
      </div>
    </div>
    <div class="alert alert-warning alert-icon" style="margin-top: 30px" v-if="infoNotFound">
      <em class="icon ni ni-alert-circle"></em> User not found!
    </div>
  </div>
</template>

<script>
import {
  getCollaborators,
  addCollaborator,
  removeCollaborator,
} from "@/services/project.service";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      member_username: "",
      member_provider: "pdb",
      infoNotFound: false,
    };
  },
  computed: {
    member_list: function () {
      return this.$store.state.project_collaborators;
    },
    user_uid: function () {
      return this.$store.state.user_uid;
    },
    project: function () {
      return this.$store.state.project;
    },
    canSave: function () {
      return this.project.member;

    },
  },
  mounted() {
    getCollaborators(this.$route.params.uid).then((data) => {
      this.$store.state.project_collaborators = data.data.collaborator_list;
    });
  },
  methods: {
    showNotFound: function () {
      this.infoNotFound = true;
      let ref = this;
      setTimeout(function () {
        ref.infoNotFound = false;
      }, 4000);
    },
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
    removeMember: function (user) {
      this.showLoader();
      removeCollaborator(user, this.$route.params.uid).then((data) => {
        if (data.data && data.data.collaborator_list) {
          this.$store.state.project_collaborators = data.data.collaborator_list;
        }
        this.hideLoader();
      });
    },
    addMember: function () {
      if (this.member_username && this.member_username.length > 2) {
        this.showLoader();

        addCollaborator(this.member_username, this.member_provider, this.$route.params.uid).then((data) => {
          console.log(data.data.collaborator_list);
          if (data.data && data.data.collaborator_list) {
            this.$store.state.project_collaborators = data.data.collaborator_list;
            this.member_username = "";
          } else {
            this.showNotFound();
          }

          this.hideLoader();
        });
      }
    },
  },
};
</script>

<style></style>
